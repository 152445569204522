import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

import CloseIcon from 'app/assets/close-icon.svg';

import Button from 'app/ui/components/atoms/button';
import Typography from 'app/ui/components/atoms/typography';

import IconWrapper from 'app/ui/components/atoms/icon';
import PageOverlay from 'app/ui/components/atoms/overlay';
import NumberSlider from 'app/ui/components/atoms/slider';

import { Pills, PillFieldSet } from 'app/ui/components/molecules/pill-field-set';

const WorkoutFilterContainer = styled.div`
  z-index: 1001;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
  overflow-y: scroll;

  ${({ theme: { spacing, colors } }) => `
    padding: ${spacing(8)};
    background: ${colors.black};
  `}

  ${({ theme: { media } }) => `
    ${media.medium(
      () => `
        width: 60%;
        border-left: 0.25px solid grey;
      `,
    )}

    ${media.large(
      () => `
        width: 30%;
      `,
    )}
  `}
`;

const FilterHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  ${({ theme: { spacing } }) => `
      margin-bottom: ${spacing(5)};
  `}

  h6 {
    width: 100%;
    text-align: center;
  }
`;

const FilterSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  ${({ theme: { spacing } }) => `
      padding: ${spacing(4)} 0;
  `}
`;

const FilterSliderSection = styled(FilterSection)`
  ${({ theme: { spacing } }) => `
      margin: 0 auto ${spacing(8)} auto;
  `}
  min-height: 6rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${({ theme: { spacing } }) => `
      padding-top: ${spacing(4)};
      gap: ${spacing(4)};
  `}
`;

const FilterButton = styled(Button)`
  width: 100%;

  ${({ theme: { spacing } }) => `
      margin: ${spacing(3)} 0;
  `}
`;

type WorkoutFilterWithDataProps = {
  equipmentFilters: Pills,
  durationFilters: Pills,
  conceptFilters: Pills,
  intensityValue: number | null,
  handleEquipmentChange: (value: string) => void,
  handleDurationChange: (value: string) => void,
  handleConceptChange: (value: string) => void,
  handleIntensityChange: Dispatch<SetStateAction<number | null>>,
  setShowMoreFilters: (value: boolean) => void,
  resetFilters: () => void,
};

const WorkoutFilter: React.FC<WorkoutFilterWithDataProps> = ({
  equipmentFilters,
  durationFilters,
  conceptFilters,
  intensityValue,
  handleEquipmentChange,
  handleIntensityChange,
  handleDurationChange,
  handleConceptChange,
  setShowMoreFilters,
  resetFilters,
}) => {
  const intl = useIntl();

  const headerText = intl.formatMessage({ id: 'filters.header' });

  const conceptLabel = intl.formatMessage({ id: 'filters.labels.concept' });
  const equipmentLabel = intl.formatMessage({ id: 'filters.labels.equipment' });
  const durationLabel = intl.formatMessage({ id: 'filters.labels.duration' });
  const intensityLabel = intl.formatMessage({ id: 'filters.labels.intensity' });

  const resetBtnText = intl.formatMessage({ id: 'filters.buttons.reset' });
  const applyBtnText = intl.formatMessage({ id: 'filters.buttons.apply' });

  return (
    <>
      <WorkoutFilterContainer>
        <FilterHeader>
          <IconWrapper
            icon={CloseIcon}
            alt="Close"
            onClick={() => setShowMoreFilters(false)}
          />
          <Typography variant="h6">{headerText}</Typography>
        </FilterHeader>

        <FilterSection>
          <PillFieldSet label={conceptLabel} pills={conceptFilters} onChange={(event) => handleConceptChange(event.target.value)} />
        </FilterSection>

        <FilterSection>
          <PillFieldSet label={equipmentLabel} pills={equipmentFilters} onChange={(event) => handleEquipmentChange(event.target.value)} />
        </FilterSection>

        <FilterSection>
          <PillFieldSet label={durationLabel} pills={durationFilters} onChange={(event) => handleDurationChange(event.target.value)} />
        </FilterSection>

        <FilterSliderSection>
          <NumberSlider
            label={intensityLabel}
            min={1}
            max={10}
            step={1}
            value={intensityValue}
            markLabels={{ min: 'RPE 1', mid: 'RPE 5', max: 'RPE 10' }}
            onChange={handleIntensityChange}
          />
        </FilterSliderSection>

        <ButtonWrapper>
          <FilterButton
            type="secondary-white"
            label={resetBtnText}
            onClick={resetFilters}
            state="default"
          />
          <FilterButton
            type="primary"
            label={applyBtnText}
            onClick={() => setShowMoreFilters(false)}
            state="default"
          />
        </ButtonWrapper>
      </WorkoutFilterContainer>
      <PageOverlay />
    </>
  );
};

export default WorkoutFilter;
