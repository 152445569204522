import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  ${({ theme: { spacing } }) => `
    width: ${spacing(6)};
    height: ${spacing(6)};
  `}
`;

type Props = {
  className?: string,
};

const IGoYouGoDark = ({ className = '' }: Props) => (
  <Svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M2 0C0.895431 0 0 0.89543 0 2V22C0 23.1046 0.89543 24 2 24H22C23.1046 24 24 23.1046 24 22V2C24 0.895431 23.1046 0 22 0H2ZM18.1898 10.3062C18.4625 10.3937 18.7038 10.5811 18.8535 10.8513C19.1659 11.4147 18.9623 12.1247 18.3988 12.437C17.8352 12.7493 17.1251 12.5457 16.8127 11.9823C16.5682 11.5412 16.6399 11.0104 16.9529 10.6508C16.8466 10.2613 16.6934 9.87956 16.4895 9.51176C15.115 7.03262 11.9906 6.13689 9.51101 7.51111L9.05203 7.79481L8.3151 6.74352L8.88883 6.38888C11.9884 4.67112 15.8938 5.79077 17.6119 8.8897C17.8659 9.34775 18.0576 9.82318 18.1898 10.3062ZM14.0405 10.8689C14.6653 11.9958 14.2581 13.4157 13.131 14.0403C12.0039 14.665 10.5837 14.2578 9.95894 13.131C9.33418 12.0041 9.74141 10.5842 10.8685 9.95953C11.9956 9.33489 13.4158 9.74204 14.0405 10.8689ZM6.87485 12.3442C7.14816 12.1353 7.32679 11.8081 7.33316 11.4374C7.34421 10.7933 6.83092 10.2619 6.18668 10.2504C5.54244 10.239 5.01122 10.7519 5.00016 11.396C4.99253 11.8411 5.2352 12.2323 5.59843 12.4354C5.65794 13.345 5.91494 14.2578 6.38759 15.1103C8.10569 18.2092 12.0112 19.3289 15.1107 17.6111L15.6844 17.2565L14.9475 16.2052L14.4885 16.4889C12.0089 17.8631 8.88451 16.9674 7.51003 14.4882C7.1329 13.808 6.92933 13.0799 6.87485 12.3442Z" fill="black"/>
  </Svg>
);

export default IGoYouGoDark;
