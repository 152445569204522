import React from 'react';
import styled, { useTheme } from 'styled-components';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';

import Typography from 'app/ui/components/atoms/typography';
import Loading from 'app/ui/components/molecules/loading';

import { GetResourceList } from 'app/pages/resource-list/resource-list.gql';
import { GetResourceListQuery, GetResourceListQueryVariables } from 'app/types/graphql';

import ChevronRight from 'app/ui/components/atoms/icons/chevron-right';

export type ResourceNavigationProps = {
  resourceListId: string;
  currentResourceId: string;
};

const NavigationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing(14)};
  margin-bottom: ${({ theme }) => theme.spacing(14)};
  gap: ${({ theme }) => theme.spacing(6)};
`;

const NavigationLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: ${({ theme }) => `${theme.spacing(3)} ${theme.spacing(5)}`};
  border: 1px solid ${({ theme }) => theme.colors.veryGray};
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.colors.black};
  transition: transform 0.2s ease;
  max-width: 45%;
  
  &:hover {
    transform: translateY(-2px);
  }
`;

const PreviousLink = styled(NavigationLink)`
  padding-left: ${({ theme }) => theme.spacing(3)};
`;

const NextLink = styled(NavigationLink)`
  padding-right: ${({ theme }) => theme.spacing(3)};
`;

const LinkText = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledChevronRight = styled(ChevronRight)`
  margin-left: ${({ theme }) => theme.spacing(3)};
`;

const ChevronLeft = styled(ChevronRight)`
  transform: rotate(-180deg);
  margin-right: ${({ theme }) => theme.spacing(3)};
`;

const ResourceNavigation: React.FC<ResourceNavigationProps> = ({ resourceListId, currentResourceId }) => {
  const { colors } = useTheme();

  const { data, loading, error } = useQuery<GetResourceListQuery, GetResourceListQueryVariables>(
    GetResourceList,
    {
      variables: { resourceListId },
      fetchPolicy: 'cache-first',
    }
  );

  if (loading) {
    return <Loading />;
  }

  if (error || !data || !data.resourceListById || !data.resourceListById.resources) {
    return null;
  }

  const { resources } = data.resourceListById;

  const currentIndex = resources.findIndex(resource => resource.id === currentResourceId);
  
  if (currentIndex === -1) {
    return null;
  }
  
  const previousResource = currentIndex > 0 ? resources[currentIndex - 1] : null;
  const nextResource = currentIndex < resources.length - 1 ? resources[currentIndex + 1] : null;

  if (!previousResource && !nextResource) {
    return null;
  }

  return (
    <NavigationContainer>
      {previousResource ? (
        <PreviousLink to={`/resources/${previousResource.id}?resourceListId=${resourceListId}`}>
          <ChevronLeft color={colors.knop} />
          <LinkText variant="button" color={colors.knop}>
            {previousResource.title}
          </LinkText>
        </PreviousLink>
      ) : (
        <></>
      )}
      {nextResource ? (
        <NextLink to={`/resources/${nextResource.id}?resourceListId=${resourceListId}`}>
          <LinkText variant="button" color={colors.knop}>
            {nextResource.title}
          </LinkText>
          <StyledChevronRight color={colors.knop} />
        </NextLink>
      ) : (
        <></>
      )}
    </NavigationContainer>
  );
};

export default ResourceNavigation;
