import cookies from 'js-cookie';
import { AlternativeExercisesActionTypes } from 'actions/alternative-exercises';
import createReducer from 'utils/create-reducer';

export type ExerciseSelection = {
  sectionExerciseId: string,
  exerciseId: string,
};

export type AlternativeExercisesState = {
  selections: ExerciseSelection[],
};

const SELECTIONS_COOKIE_NAME = 'alternative_exercises_selections';

const getPersistedSelections = (): ExerciseSelection[] => {
  const savedSelections = cookies.get(SELECTIONS_COOKIE_NAME);
  if (savedSelections) {
    try {
      return JSON.parse(savedSelections);
    } catch (error) {
      return [];
    }
  }
  return [];
};

const persistSelections = (selections: ExerciseSelection[]): void => {
  cookies.set(SELECTIONS_COOKIE_NAME, JSON.stringify(selections), { 
    domain: window.location.hostname,
  });
};

export const initialAlternativeExercisesState: AlternativeExercisesState = {
  selections: getPersistedSelections(),
};

const handlers = {
  [AlternativeExercisesActionTypes.SET_ALTERNATIVE_EXERCISE]: (
    state: AlternativeExercisesState, 
    payload: ExerciseSelection
  ): AlternativeExercisesState => {
    const filtered = state.selections.filter((selection) => (
      selection.sectionExerciseId !== payload.sectionExerciseId
    ));

    const newSelection = [...filtered, payload];
    persistSelections(newSelection);

    return {
      ...state,
      selections: newSelection,
    };
  },
  
  [AlternativeExercisesActionTypes.REMOVE_ALTERNATIVE_EXERCISE]: (
    state: AlternativeExercisesState, 
    payload: { sectionExerciseId: string }
  ): AlternativeExercisesState => {
    const filteredSelections = state.selections.filter((selection) => (
      selection.sectionExerciseId !== payload.sectionExerciseId
    ));

    persistSelections(filteredSelections);

    return {
      ...state,
      selections: filteredSelections,
    };
  },

  [AlternativeExercisesActionTypes.RESET_ALTERNATIVE_EXERCISES]: (): AlternativeExercisesState => {
    cookies.remove(SELECTIONS_COOKIE_NAME);
    return initialAlternativeExercisesState;
  },
};

export const alternativeExercisesReducer = createReducer<AlternativeExercisesState>(handlers);
