import React, { useEffect } from 'react';
import styled from 'styled-components';
import useTimer from 'app/hooks/use-timer';

import { onSlideChanged } from 'app/hooks/use-timer/util';

type CountdownTimerProps = {
  startSeconds: number,
  className?: string,
  timerId: string,
  isPresenterView?: boolean,
};

const TimerWrapper = styled.div<{ $isPresenterView?: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(3)}`};
  border: ${({ theme, $isPresenterView }) => $isPresenterView ? 'none' : `2px solid ${theme.colors.green}`};
  background: ${({ theme, $isPresenterView }) => $isPresenterView ? 'transparent' : theme.colors.greenWithTransparency};
  border-radius: ${({ theme }) => theme.spacing(3)};
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
  font-size: ${({ theme }) => theme.typography.jumbo};
  width: auto;
`;

const Digit = styled.span`
  display: inline-block;
  width: 0.97ch;
  text-align: center;
`;

const Separator = styled.div`
  margin: 0 ${({ theme }) => theme.spacing(1)};
`;

const CountdownTimer: React.FC<CountdownTimerProps> = ({ 
  startSeconds, 
  className, 
  timerId,
  isPresenterView = false,
}) => {
  const { timeRemaining, reset } = useTimer({
    timerId,
    initialDuration: startSeconds,
  });

  useEffect(() => (
    onSlideChanged(() => {
      reset();
    })
  ), []);

  const minutes = String(Math.floor(timeRemaining / 60)).padStart(2, '0');
  const seconds = String(timeRemaining % 60).padStart(2, '0');

  return (
    <TimerWrapper className={className} $isPresenterView={isPresenterView}>
      <Digit>{minutes[0]}</Digit>
      <Digit>{minutes[1]}</Digit>
      <Separator>:</Separator>
      <Digit>{seconds[0]}</Digit>
      <Digit>{seconds[1]}</Digit>
    </TimerWrapper>
  );
};

export default CountdownTimer;
