import React, { ReactNode } from 'react';
import styled, { useTheme } from 'styled-components';

import { LessonSectionFormat, Video } from 'app/types/graphql';
import { HandleSectionFocusChangeProps } from 'app/pages/workout';

import Typography from 'app/ui/components/atoms/typography';
import ExerciseItem from 'app/ui/components/molecules/exercise-item';

type BaseContentData = {
  title: string,
  content?: string | ReactNode | null,
  extraContent?: string | null,
  additionalInfo: string,
  id: string,
};

export type ContentItemData = BaseContentData & {
  originalExerciseId?: string,
  video?: Video | null,
  notes?: string | null,
  alternativeExercises?: ContentItemData[] | null,
};

export type GroupContentItemData = BaseContentData & {
  sectionExercises: ContentItemData[],
  showTitle: boolean,
  notes?: string | null,
};

export type ContentData = BaseContentData & {
  titleExtraContent: ReactNode,
  contentItems?: ReactNode,
  notes?: string | null,
  groups?: GroupContentItemData[] | null,
  format?: LessonSectionFormat,
  duration?: number,
};

export type ContentItemsAreaProps = {
  items: GroupContentItemData[],
  contentItemFocused: string | null,
  handleContentItemClick: (prop: HandleSectionFocusChangeProps) => void,
}

const Title = styled(Typography)`
  margin: ${({ theme: { spacing } }) => `${spacing(4)} 0 ${spacing(2)} 0`};
`;

const ContentItemArea = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const ContentItem = styled.div`
  width: 100%;
`;

const GroupDescription = styled.div`
  margin-bottom: ${({ theme: { spacing } }) => `${spacing(2)}`};
`;

const ContentItemAreasWrapper: React.FC<ContentItemsAreaProps> = ({
  items,
  contentItemFocused,
  handleContentItemClick,
}) => {
  const theme = useTheme();

  if (!items.length) {
    return <></>;
  }

  return (
    <>
      {items.map(({ id: groupId, title, content, showTitle, sectionExercises }) => {
        return (
          <ContentItemArea key={`group-${groupId}`}>
            <ContentItem>
              {showTitle && (
                <>
                  <Title variant="body2" color={theme.colors.paragraphGray}>{title}</Title>
                  {content && (
                    <GroupDescription>
                      <Typography variant="caption" color={theme.colors.paragraphGray}>
                        {content}
                      </Typography>
                    </GroupDescription>
                  )}
                </>
              )}
              <div>
                {sectionExercises?.map((sectionExercise, index) => (
                  <ExerciseItem
                    isOpen={contentItemFocused === sectionExercise.id}
                    groupId={groupId}
                    initialExercise={sectionExercise}
                    onClick={handleContentItemClick}
                    index={index}
                  />
                ))}
              </div>
            </ContentItem>
          </ContentItemArea>
        )
      }
      )
    }
    </>
  );
};

export default ContentItemAreasWrapper;
