import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  ${({ theme: { spacing } }) => `
    width: ${spacing(6)};
    height: ${spacing(6)};
  `}
`;

type Props = {
  color: string,
  className?: string,
};

const Sandbag = ({ color, className = '' }: Props) => (
  <Svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.00004 7.89792C5.7239 7.89792 5.50004 8.12178 5.50004 8.39792V8.86571H6.013V16.6021H4.71259C3.88417 16.6021 3.21259 15.9305 3.21259 15.1021V10.3657C3.21259 9.60944 3.77227 8.98388 4.50004 8.88065V8.39792C4.50004 7.56949 5.17161 6.89792 6.00004 6.89792H10C10.8285 6.89792 11.5 7.56949 11.5 8.39792V8.86572L12.4869 8.86572V8.39792C12.4869 7.56949 13.1585 6.89792 13.9869 6.89792H17.9869C18.8153 6.89792 19.4869 7.56949 19.4869 8.39792V8.93558C20.0935 9.12787 20.5331 9.69546 20.5331 10.3657V15.1021C20.5331 15.9305 19.8615 16.6021 19.0331 16.6021H17.7327V8.86572L18.4869 8.86572V8.39792C18.4869 8.12178 18.263 7.89792 17.9869 7.89792H13.9869C13.7107 7.89792 13.4869 8.12178 13.4869 8.39792V8.86572L17.1711 8.86572V16.6021L6.60792 16.6021L6.60792 8.86572L10.5 8.86572V8.39792C10.5 8.12178 10.2762 7.89792 10 7.89792H6.00004Z" fill={color}/>
    <path d="M2.97434 15.1849C2.62531 15.1849 2.2797 15.1215 1.95724 14.9983C1.63478 14.8751 1.34178 14.6946 1.09498 14.467C0.848182 14.2394 0.652409 13.9692 0.518842 13.6718C0.385274 13.3745 0.316528 13.0558 0.316528 12.7339C0.316528 12.412 0.385275 12.0933 0.518842 11.7959C0.65241 11.4986 0.848182 11.2284 1.09498 11.0008C1.34178 10.7732 1.63478 10.5927 1.95724 10.4695C2.2797 10.3463 2.62531 10.2829 2.97434 10.2829L2.97434 11.2579C2.76415 11.2579 2.55602 11.2961 2.36182 11.3702C2.16763 11.4444 1.99119 11.5531 1.84256 11.6902C1.69393 11.8273 1.57604 11.99 1.4956 12.169C1.41516 12.3481 1.37376 12.5401 1.37376 12.7339C1.37376 12.9277 1.41516 13.1197 1.4956 13.2987C1.57604 13.4778 1.69393 13.6405 1.84256 13.7776C1.99119 13.9147 2.16763 14.0234 2.36182 14.0976C2.55602 14.1717 2.76415 14.2099 2.97434 14.2099V15.1849Z" fill={color}/>
    <path d="M21.9789 10.4695C21.6565 10.3463 21.3109 10.2829 20.9618 10.2829V11.2579C21.172 11.2579 21.3802 11.2961 21.5743 11.3702C21.7685 11.4444 21.945 11.5531 22.0936 11.6902C22.2422 11.8273 22.3601 11.99 22.4406 12.1691C22.521 12.3481 22.5624 12.5401 22.5624 12.7339C22.5624 12.9277 22.521 13.1197 22.4406 13.2988C22.3601 13.4778 22.2422 13.6405 22.0936 13.7776C21.945 13.9147 21.7685 14.0234 21.5743 14.0976C21.3802 14.1717 21.172 14.2099 20.9618 14.2099V15.1849C21.3109 15.1849 21.6565 15.1215 21.9789 14.9983C22.3014 14.8751 22.5944 14.6946 22.8412 14.467C23.088 14.2394 23.2838 13.9692 23.4173 13.6719C23.5509 13.3745 23.6196 13.0558 23.6196 12.7339C23.6196 12.412 23.5509 12.0933 23.4173 11.796C23.2838 11.4986 23.088 11.2284 22.8412 11.0008C22.5944 10.7732 22.3014 10.5927 21.9789 10.4695Z" fill={color}/>
  </Svg>
);

export default Sandbag;
