import React from 'react';
import styled from 'styled-components';

const Icon = styled.div`
  ${({ theme: { spacing } }) => `
    width: ${spacing(4)};
    height: ${spacing(4)};
  `}

  align-items: baseline;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }
`;

type Props = {
  icon: string,
  alt: string,
  onClick?: () => void,
};

const IconWrapper: React.FC<Props> = ({ icon, alt, onClick }: Props) => (
  <Icon onClick={onClick}>
    <img src={icon} alt={alt} />
  </Icon>
);

export default IconWrapper;
