import React, { useMemo, useState, useEffect } from 'react';
import styled, { useTheme } from 'styled-components';
import { useAppState, useDispatch } from 'state';
import { setAlternativeExercise, removeAlternativeExercise } from 'actions/alternative-exercises';

import ChevronDownIcon from 'app/assets/chevron-down-icon.svg';
import ChevronUpIcon from 'app/assets/chevron-up-icon.svg';
import Typography from 'app/ui/components/atoms/typography';

import { HandleSectionFocusChangeProps } from 'app/pages/workout';

import ExercisePicker from 'app/ui/components/molecules/picker/exercise';
import { ContentItemData } from 'app/ui/components/molecules/content-item-areas-wrapper';

type ExerciseItemProps = {
  groupId: string,
  isOpen: boolean,
  initialExercise: ContentItemData,
  onClick: (prop: HandleSectionFocusChangeProps) => void,
  index?: number,
};

const ExercisePickerContainer = styled.div`
  flex-grow: 1;
`;

const TitleText = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
`;

const Description = styled(Typography)`
  li {
    margin-left: 20px;
  }
`;

const ExerciseTitle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  cursor: pointer;
  padding: 0.25rem 0 0.25rem 1rem;
`;

const StyledExerciseItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: -1px;

  ${({ theme }) => `
    border-top: 1px solid ${theme.colors.devilsGray};
    border-bottom: 1px solid ${theme.colors.devilsGray};
  `}
`;

const Content = styled.div<{$isOpen: boolean}>`
  ${({ $isOpen }) => `display: ${$isOpen ? 'flex' : 'none'}`};
  flex-direction: column;
  padding: 1rem 0 1rem 1rem;
`;

const ExerciseItem: React.FC<ExerciseItemProps> = ({
  groupId,
  isOpen,
  initialExercise,
  onClick,
  index,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const initialExerciseData = useMemo(() => initialExercise, []);
  const alternativeExercises = useMemo(() => initialExercise.alternativeExercises || [], []);

  const sectionExerciseId = initialExerciseData.id;
  const originalExerciseId = initialExerciseData.originalExerciseId;

  const selectedExerciseId = useAppState((state) => {
    const selection = state.alternativeExercises.selections.find((selection) => (
      selection.sectionExerciseId === sectionExerciseId
    ));
    return selection?.exerciseId;
  });

  const findSelectedExercise = (): ContentItemData => {
    if (!selectedExerciseId) {
      return initialExerciseData;
    }

    const altExercise = alternativeExercises.find((exercise) => exercise.id === selectedExerciseId);

    return altExercise || initialExerciseData;
  };
  
  const [exercisePicked, setExercisePicked] = useState<ContentItemData>(findSelectedExercise());
  
  useEffect(() => {
    setExercisePicked(findSelectedExercise());
  }, [selectedExerciseId]);

  const handleClick = () => {
    if (isOpen) {
      return onClick({ sectionExerciseKey: null });
    }

    return onClick({
      sectionExerciseKey: initialExerciseData.id,
      exerciseKey: exercisePicked.id,
    });
  };

  const handlePickedClick = (exercise: ContentItemData): void => {
    setExercisePicked(exercise);

    if (exercise.id === originalExerciseId) {
      dispatch(removeAlternativeExercise(sectionExerciseId));
    } else {
      dispatch(setAlternativeExercise({
        sectionExerciseId,
        exerciseId: exercise.id,
      }));
    }

    onClick({ exerciseKey: exercise.id });
  }

  return (
    <StyledExerciseItem key={`${groupId}-exercise-${index}`} onClick={handleClick}>
      <ExerciseTitle>
        {exercisePicked.additionalInfo && (
          <Typography variant='body2' color={isOpen ? theme.colors.focoltone : theme.colors.paragraphGray}>
            {exercisePicked.additionalInfo}
          </Typography>
        )}
        {alternativeExercises.length > 0 ? (
          <ExercisePickerContainer>
            <ExercisePicker
              exercisePicked={exercisePicked}
              exercises={[initialExercise, ...alternativeExercises]}
              onClick={(event) => event.stopPropagation()}
              onChange={handlePickedClick}
            />
          </ExercisePickerContainer>
        ) : (
          <TitleText variant='body2' color={isOpen ? theme.colors.focoltone : theme.colors.paragraphGray}>
            {exercisePicked.title}
          </TitleText>
        )}
        {isOpen ? <img src={ChevronUpIcon} /> : <img src={ChevronDownIcon} />}
      </ExerciseTitle>
      <Content $isOpen={isOpen}>
        {exercisePicked.content && (
          typeof exercisePicked.content === 'string' ? (
            <Description variant='caption' color={theme.colors.paragraphGray}>
              <span dangerouslySetInnerHTML={{ __html: exercisePicked.content }} />
            </Description>
          ) : (
            <Description variant='caption' color={theme.colors.paragraphGray}>{exercisePicked.content}</Description>
          )
        )}
        <Typography variant='caption' color={theme.colors.paragraphGray}>{exercisePicked.extraContent}</Typography>
      </Content>
    </StyledExerciseItem>
  );
};

export default ExerciseItem;
