import React, { ReactNode, useRef, useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import ChevronRight from 'app/ui/components/atoms/icons/chevron-right';

interface HorizontalRailProps {
  children: ReactNode,
  title?: ReactNode,
}

const RailWrapper = styled.div`
  position: relative;
`;

const RailContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  height: auto;

  ${({ theme: { spacing } }) => `
    margin: ${spacing(8)} 0 ${spacing(10)};
  `}
`;

const Rail = styled.div`
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  white-space: nowrap;
  height: fit-content;

  &::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;

  ${({ theme: { spacing } }) => `
    gap: ${spacing(4)};
  `}
`;

const ArrowWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50px;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  height: 100%; 
`;

const Arrow = styled.button`
  color: white;
  border: none;
  cursor: pointer;
  background: none;
`;

const LeftArrowWrapper = styled(ArrowWrapper)`
  left: 0;
`;

const RightArrowWrapper = styled(ArrowWrapper)`
  right: 0;
`;

const ProgressBarContainer = styled.div`
  width: 80px;
  height: 4px;
  border-radius: 3px;
  overflow: hidden;
  display: none;

  ${({ theme: { colors, media } }) => `
    background: ${colors.veryGray};

    ${media.medium(() => `display: block`)};
  `}
`;

const ProgressBar = styled.div<{ progress: number }>`
  height: 100%;
  width: ${({ progress }) => progress}%;
  transition: width 0.05s ease;

  ${({ theme: { colors } }) => `
    background: ${colors.lightGray};
  `}
`;

const TitleWithProgress = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;

  ${({ theme: { spacing } }) => `
    margin-bottom: ${spacing(4)};
  `}
`;

const ChevronLeft = styled(ChevronRight)`
  transform: rotate(-180deg);
`;

const HorizontalRail: React.FC<HorizontalRailProps> = React.memo(({ children, title }) => {
  const railRef = useRef<HTMLDivElement>(null);
  const [initialScrollProgress, setInitialScrollProgress] = useState(0);
  const [scrollProgress, setScrollProgress] = useState(0);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);

  const calculateScrollProgress = useCallback(() => {
    const container = railRef.current;
    if (container) {
      const maxScrollLeft = container.scrollWidth - container.clientWidth;
      const scrollLeft = container.scrollLeft;
      const visibleWidth = container.clientWidth;

      const visiblePercentage = (visibleWidth / container.scrollWidth) * 100;
      const progress = initialScrollProgress + ((scrollLeft / maxScrollLeft) * (100 - visiblePercentage));

      if (initialScrollProgress === 0) {
        setTimeout(() => {
          const newVisiblePercentage = (visibleWidth / container.scrollWidth) * 100;
          setInitialScrollProgress(newVisiblePercentage);
          setScrollProgress(newVisiblePercentage);
        }, 300);
        return;
      }

      setScrollProgress(progress);
      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft < maxScrollLeft);
    }
  }, [initialScrollProgress]);

  const handleScroll = (direction: number) => {
    const container = railRef.current;
    if (container) {
      const scrollAmount = container.offsetWidth;
      const newScrollPosition = container.scrollLeft + (direction * scrollAmount);
      container.scrollTo({ left: newScrollPosition, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    calculateScrollProgress();

    const container = railRef.current;
    if (container) {
      container.addEventListener('scroll', calculateScrollProgress);
    }

    window.addEventListener('resize', calculateScrollProgress);

    return () => {
      if (container) {
        container.removeEventListener('scroll', calculateScrollProgress);
      }
      window.removeEventListener('resize', calculateScrollProgress);
    };
  }, [calculateScrollProgress]);

  return (
    <RailContainer>
      <TitleWithProgress>
        {title}
        <ProgressBarContainer>
          <ProgressBar progress={scrollProgress} />
        </ProgressBarContainer>
      </TitleWithProgress>
      <RailWrapper>
        <Rail ref={railRef}>
          {children}
        </Rail>
        {showLeftArrow && (
          <LeftArrowWrapper>
            <Arrow onClick={() => handleScroll(-1)}><ChevronLeft color='white' /></Arrow>
          </LeftArrowWrapper>
        )}
        {showRightArrow && (
          <RightArrowWrapper>
            <Arrow onClick={() => handleScroll(1)}><ChevronRight color='white' /></Arrow>
          </RightArrowWrapper>
        )}
      </RailWrapper>
    </RailContainer>
  );
});

export default HorizontalRail;
