import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

type Props = {
  imageUrl: string;
  mobileImageUrl?: string;
  linkUrl?: string;
};

const BannerImage = styled.div<{ imageUrl: string, mobileImageUrl?: string }>`
  aspect-ratio: calc(10/3);
  background-image: url(${({ imageUrl, mobileImageUrl }) => mobileImageUrl || imageUrl});
  background-size: cover;
  background-position: center;
  ${({ theme: { spacing, media }, imageUrl }) => `
    ${media.medium(() => `
        aspect-ratio: 10;
        background-image: url(${imageUrl});
    `)}
    margin-bottom: ${spacing(4)};
  `}
`;

const Banner: React.FC<Props> = ({
  imageUrl,
  mobileImageUrl,
  linkUrl,
}) => {
  if (linkUrl) {
    return (
        <Link to={linkUrl} target="_blank">
          <BannerImage imageUrl={imageUrl} mobileImageUrl={mobileImageUrl} />
        </Link>
    );
  }

  return <BannerImage imageUrl={imageUrl} mobileImageUrl={mobileImageUrl} />;
};

export default Banner;
