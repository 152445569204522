import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  ${({ theme: { spacing } }) => `
    width: ${spacing(6)};
    height: ${spacing(6)};
  `}
`;

type Props = {
  color: string,
  className?: string,
};

const Medball = ({ color, className = '' }: Props) => (
  <Svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6375_9269)">
    <path d="M3 11.24C3.1312 10.6192 3.2248 9.988 3.4008 9.38C3.8888 7.6984 4.8416 6.3104 6.1512 5.1552C6.3464 4.9832 6.488 4.9784 6.704 5.1176C7.0712 5.3544 7.4632 5.5512 7.8896 5.7896C5.9096 9.9216 5.9096 14.0744 7.8872 18.2032C7.42 18.4696 6.9992 18.724 6.5632 18.9472C6.4792 18.9904 6.3008 18.9552 6.2232 18.8888C4.4424 17.3616 3.3728 15.4432 3.0752 13.1056C3.0568 12.9632 3.0256 12.8216 3 12.68C3 12.2 3 11.72 3 11.24Z" fill={color}/>
    <path d="M20.9999 12.68C20.8999 13.2136 20.8263 13.7536 20.6943 14.2784C20.2351 16.1024 19.2535 17.6072 17.8455 18.844C17.6719 18.996 17.5407 19.0392 17.3271 18.9008C16.9495 18.656 16.5471 18.4512 16.1335 18.2176C17.0031 16.2272 17.6127 14.1712 17.6087 11.9784C17.6047 9.8008 16.9975 7.7568 16.1343 5.7832C16.5823 5.528 17.0031 5.2744 17.4391 5.0512C17.5231 5.008 17.7015 5.0432 17.7783 5.1096C19.5575 6.6376 20.6263 8.556 20.9239 10.892C20.9423 11.0352 20.9735 11.1768 20.9991 11.3184V12.6784L20.9999 12.68Z" fill={color}/>
    <path d="M12.68 3C13.3416 3.1352 14.0192 3.22 14.6616 3.4184C15.324 3.6224 15.952 3.936 16.6576 4.2272C14.0112 5.9856 10.02 5.9952 7.41443 4.2768C8.20643 3.768 9.05683 3.4496 9.94963 3.2424C10.4016 3.1376 10.8632 3.0792 11.32 3C11.7736 3 12.2264 3 12.68 3Z" fill={color}/>
    <path d="M11.32 21C10.6712 20.868 10.0072 20.7848 9.3776 20.5912C8.7104 20.3864 8.0744 20.0784 7.38 19.7968C9.5624 18.1544 13.8008 17.8632 16.5856 19.7248C15.7936 20.2312 14.9432 20.5528 14.0496 20.7576C13.5984 20.8616 13.1368 20.92 12.68 20.9992H11.32V21Z" fill={color}/>
    <path d="M8.89754 6.1552C10.9679 6.7952 13.0383 6.792 15.1031 6.1576C16.9919 10.0576 16.9967 13.9336 15.1031 17.84C13.0463 17.2104 10.9767 17.2024 8.89914 17.8448C7.00714 13.9552 7.00074 10.0816 8.89754 6.1552Z" fill={color}/>
    </g>
    <defs>
    <clipPath id="clip0_6375_9269">
    <rect width="18" height="18" fill="white" transform="translate(3 3)"/>
    </clipPath>
    </defs>
  </Svg>
);

export default Medball;
