import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Link } from 'react-router-dom';
import Typography from 'app/ui/components/atoms/typography';

import { ResourceList } from 'app/types/graphql';

export type ResourceListCardProps = {
  resourceList: ResourceList,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.veryGray};
`;

const HeaderWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
  aspect-ratio: 2.66;
  min-height: 9.375rem;
  cursor: pointer;
  
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background:
      linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.2) 50%, rgba(0, 0, 0, 0.4) 100%);
  }

  ${({ theme: { media } }) => {
    return `
      ${media.medium(
        () => `
          aspect-ratio: 3;
        `,
      )}
    `;
  }};
`;

const HeaderImage = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const TitleOverlay = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 1;
  padding: ${({ theme }) => theme.spacing(6)};
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  ${({ theme: { colors, spacing } }) => `
    padding: ${spacing(6)};
    background-color: ${colors.black};
    gap: ${spacing(4)};
  `};
`;

const LinksList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
`;

const LinkItem = styled.li`
  margin: 0;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  display: block;
`;

const StyledTypography = styled(Typography)`
  line-height: ${({ theme }) => theme.spacing(6)};

  &:hover {
    color: ${({theme}) => theme.colors.focoltone};
  }
`;

const ResourceListCard: React.FC<ResourceListCardProps> = ({
  resourceList: {
    id,
    title,
    image,
    resourceLists,
    resources,
  },
}) => {
  const theme = useTheme();

  return (
    <Container>
      <Link to={`/resource-lists/${id}`}>
        <HeaderWrapper>
          {image?.signedUrl && <HeaderImage loading="lazy" src={image.signedUrl} alt={title} />}
          <TitleOverlay>
            <Typography variant="h4" color={theme.colors.white}>
              {title}
            </Typography>
          </TitleOverlay>
        </HeaderWrapper>
      </Link>
      <ContentContainer>
        <LinksList>
          {resourceLists?.map((resourceList) => (
            <LinkItem key={`resource-list-${resourceList.id}`}>
              <StyledLink to={`/resource-lists/${resourceList.id}`}>
                <StyledTypography variant="body2" color={theme.colors.lightGray}>
                  {resourceList.title}
                </StyledTypography>
              </StyledLink>
            </LinkItem>
          ))}
          {resources?.map((resource) => (
            <LinkItem key={`resource-${resource.id}`}>
              <StyledLink to={`/resources/${resource.id}?resourceListId=${id}`}>
                <StyledTypography variant="body2" color={theme.colors.lightGray}>
                  {resource.title}
                </StyledTypography>
              </StyledLink>
            </LinkItem>
          ))}
        </LinksList>
      </ContentContainer>
    </Container>
  );
};

export default ResourceListCard;
