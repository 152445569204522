import React from 'react';
import styled, { useTheme } from 'styled-components';

import Typography from 'app/ui/components/atoms/typography';
import { GroupContentItemData } from 'app/ui/components/molecules/content-item-areas-wrapper';

type GroupBreakdownProps = {
  group: GroupContentItemData;
  index: number;
  isSingleGroup?: boolean;
  isSmallVariant?: boolean;
  isOverviewMode?: boolean;
};

const GroupWrapper = styled.div<{ $isSmallVariant?: boolean }>`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing(4.5)};
  margin: ${({ theme: { spacing }, $isSmallVariant }) => $isSmallVariant ? '0' : `${spacing(2.5)} 0`};
`;

const ZoneColumn = styled.div<{ $isSmallVariant?: boolean }>`
  align-items: flex-start;
  margin-right: ${({ theme, $isSmallVariant }) => $isSmallVariant ? 0 : theme.spacing(1)};
  min-width: 5rem;

  ${({ theme: { media }, $isSmallVariant }) => `${media.medium(() => `
      min-width: ${$isSmallVariant ? '5rem' : '7.5rem'};
    `)}
  `};
`;

const ZoneTitle = styled(Typography)`
  font-weight: ${({ theme }) => theme.typography.bold};
`;

const ExercisesColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { spacing } }) => spacing(1.5)};
`;

const ExerciseWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ExerciseDetailsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const GroupNote = styled(Typography)`
  margin: 0;
`;

const Note = styled(Typography)<{ $isSingleGroup: boolean }>`
  margin-top: ${({ theme }) => theme.spacing(1.5)};
  margin-left: ${({ theme: { spacing }, $isSingleGroup  }) =>
    $isSingleGroup ? `calc(${spacing(10)} + ${spacing(4)});` : 0});
`;

const IndexNumber = styled(Typography)`
  white-space: nowrap;
  flex-shrink: 0;
  min-width: ${({ theme }) => theme.spacing(10)};
  margin-right: ${({ theme }) => theme.spacing(4)};
`;

const ExerciseName = styled(Typography)`
  flex: 1;
`;

const GroupBreakdown: React.FC<GroupBreakdownProps> = ({
  group,
  index,
  isSingleGroup,
  isSmallVariant,
  isOverviewMode,
}) => {
  const theme = useTheme();

  const applySmallVariant = isSmallVariant || isOverviewMode;

  const textVariant = applySmallVariant ? 'whiteboard2' : 'whiteboard1';

  return (
    <GroupWrapper $isSmallVariant={applySmallVariant}>
      {!isSingleGroup && (
        <ZoneColumn $isSmallVariant={applySmallVariant}>
          <ZoneTitle variant={textVariant} color={theme.colors.focoltone}>
            ZONE {index}
          </ZoneTitle>
        </ZoneColumn>
      )}
      <ExercisesColumn>
        {!isOverviewMode && group.notes && group.notes.length > 0 && (
          <GroupNote variant={textVariant} color={theme.colors.lightGray}>
            {group.notes}
          </GroupNote>
        )}
        {group.sectionExercises.map((exercise, i) => (
          <ExerciseWrapper key={`exercise-${index}-${i}`}>
            <ExerciseDetailsWrapper>
              {isSingleGroup && <IndexNumber variant={textVariant} color={theme.colors.focoltone}>#{i+1}</IndexNumber>}
              <ExerciseName variant={textVariant} color={theme.colors.white}>{`${exercise.additionalInfo} ${exercise.title}`}</ExerciseName>
            </ExerciseDetailsWrapper>
            {!isOverviewMode && exercise?.notes && exercise.notes.length > 0 && (
              <Note $isSingleGroup={isSingleGroup || false} variant={textVariant} color={theme.colors.lightGray}>
                {exercise.notes}
              </Note>
            )}
          </ExerciseWrapper>
        ))}
      </ExercisesColumn>
    </GroupWrapper>
  );
};

export default GroupBreakdown;
