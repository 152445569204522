import React from 'react';
import styled from 'styled-components';

import CountdownTimer from 'app/ui/components/molecules/countdown';

export interface SlideTimerProps {
  startSeconds: number
  timerId: string
  isPresenterView?: boolean
}

const TimerContainer = styled.div<{ $isPresenterView?: boolean }>`
  position: ${({ $isPresenterView }) => $isPresenterView ? 'block' : 'absolute'};
  top: 2rem;
  right: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.5rem;
`;

const TopCountdownTimer = styled(CountdownTimer)`
  display: inline-flex;
`;

const SlideTimer: React.FC<SlideTimerProps> = ({
  startSeconds,
  timerId,
  isPresenterView = false,
}) => (
  <TimerContainer>
    <TopCountdownTimer 
      startSeconds={startSeconds} 
      timerId={timerId}
      isPresenterView={isPresenterView}
    />
  </TimerContainer>
);

export default SlideTimer;
