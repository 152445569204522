import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';

import ChevronUp from 'app/ui/components/atoms/icons/chevron-up';
import ChevronDown from 'app/ui/components/atoms/icons/chevron-down';
import RoundTick from 'app/ui/components/atoms/icons/round-tick';

import {
  DropdownButton,
  DropdownContainer,
  DropdownItem,
  DropdownList,
  Label,
} from 'app/ui/components/molecules/picker';
import { ContentItemData } from 'app/ui/components/molecules/content-item-areas-wrapper';

type ExercisePickerProps = {
  exercises: ContentItemData[],
  exercisePicked: ContentItemData | null,
  onChange: (exercise: ContentItemData) => void,
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void,
};

const StyledDropdownButton = styled(DropdownButton)`
  gap: ${({ theme: { spacing } }) => spacing(1)};
  height: ${({ theme: { spacing } }) => spacing(7)};
  padding: 0 ${({ theme: { spacing } }) => spacing(2)};
`;

const ExercisePicker: React.FC<ExercisePickerProps> = ({ exercises, exercisePicked, onChange, onClick }) => {
  const theme = useTheme();

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);
  const selectExercise = (exercise: ContentItemData) => {
    onChange(exercise);
    setIsOpen(false);
  };

  const { colors: { focoltone, white } } = theme;

  return (
    <DropdownContainer onClick={onClick}>
      <StyledDropdownButton onClick={toggleDropdown}>
        <Label $isOpen={isOpen}>{exercisePicked?.title}</Label>
        {isOpen ?
          <ChevronUp color={isOpen ? focoltone : white} /> :
          <ChevronDown color={isOpen ? focoltone : white} />
        }
      </StyledDropdownButton>
      {isOpen && (
        <DropdownList>
          {exercises.map((exercise) => (
            <DropdownItem key={exercise.id} onClick={() => selectExercise(exercise)} >
              {exercise.title}
              {exercise.id === exercisePicked?.id && <RoundTick color={white} />}
            </DropdownItem>
          ))}
        </DropdownList>
      )}
    </DropdownContainer>
  );
};

export default ExercisePicker;
