import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';

import { Language } from 'app/types/graphql';

import WorldIcon from 'app/ui/components/atoms/icons/world';
import ChevronUp from 'app/ui/components/atoms/icons/chevron-up';
import ChevronDown from 'app/ui/components/atoms/icons/chevron-down';
import RoundTick from 'app/ui/components/atoms/icons/round-tick';

import {
  DropdownButton,
  DropdownContainer,
  DropdownItem,
  DropdownList,
  Label,
} from 'app/ui/components/molecules/picker';

type LanguagePickerProps = {
  languages: Language[],
  preferredLanguage: Language | null,
  onChange: (language: Language) => void,
};

const StyledDropdownButton = styled(DropdownButton)`
  width: 6rem;
  text-transform: uppercase;
`;

const LanguagePicker: React.FC<LanguagePickerProps> = ({ languages, preferredLanguage, onChange }) => {
  const theme = useTheme();

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);
  const selectLanguage = (language: Language) => {
    onChange(language);
    setIsOpen(false);
  };

  const { colors: { focoltone, white } } = theme;

  return (
    <DropdownContainer>
      <StyledDropdownButton onClick={toggleDropdown}>
        <WorldIcon color={isOpen ? focoltone : white} />
        <Label $isOpen={isOpen}>{preferredLanguage?.code?.substring(0, 2) || ''}</Label>
        {isOpen ?
          <ChevronUp color={isOpen ? focoltone : white} /> :
          <ChevronDown color={isOpen ? focoltone : white} />
        }
      </StyledDropdownButton>
      {isOpen && (
        <DropdownList>
          {languages.map((language) => (
            <DropdownItem key={language.code} onClick={() => selectLanguage(language)} >
              {language.name}
              {language.code === preferredLanguage?.code && <RoundTick color={white} />}
            </DropdownItem>
          ))}
        </DropdownList>
      )}
    </DropdownContainer>
  );
};

export default LanguagePicker;
