import React from 'react';
import styled, { useTheme } from 'styled-components';
import { useQuery } from '@apollo/client';
import { useIntl } from 'react-intl';
import useConfig from 'app/config';

import GenericPage from 'app/ui/components/layouts/generic-page';

import PageTitle from 'app/ui/components/molecules/page-title';
import Error from 'app/ui/components/molecules/error';
import Loading from 'app/ui/components/molecules/loading';
import Typography from 'app/ui/components/atoms/typography';

import { GetRootResourceList } from 'app/pages/resource-lists-home/root-resource-list.gql';
import { GetRootResourceListQuery, GetRootResourceListQueryVariables } from 'app/types/graphql';

import ResourceListGrid from 'app/ui/components/molecules/resource-list-card/grid';

import { Container } from 'app/ui/components/atoms/resource-list/container';
import { TitleWrapper } from 'app/ui/components/atoms/resource-list/title-wrapper';
import { ResourceListDescription } from 'app/ui/components/atoms/resource-list/description';

const ResourceListHomePage: React.FC = () => {
  const theme = useTheme();
  const intl = useIntl();

  const { config } = useConfig();

  const { data, loading, error } = useQuery<
    GetRootResourceListQuery, GetRootResourceListQueryVariables
  >(GetRootResourceList, {
    variables: { resourceListId: config.REACT_APP_ROOT_RESOURCE_LIST_ID },
  });

  if (loading) {
    return <Loading />;
  }

  if (error || !data || !data.resourceListById) {
    return <Error />;
  }

  const resourcesHeader = intl.formatMessage({ id: 'resources.header' });
  const resourcesCopy = intl.formatMessage({ id: 'resources.copy' });

  return (
    <GenericPage>
      <Container>
        <TitleWrapper>
          <PageTitle>
            <Typography variant="h3" color={theme.colors.white}>
              {resourcesHeader}
            </Typography>
          </PageTitle>
          <ResourceListDescription variant="body2" color={theme.colors.lightGray}>
            {resourcesCopy}
          </ResourceListDescription>
        </TitleWrapper>
        <ResourceListGrid resourceLists={data.resourceListById.resourceLists || []} />
      </Container>
    </GenericPage>
  );
};
export default ResourceListHomePage;
