import React from 'react';
import ResourceListCard from 'app/ui/components/molecules/resource-list-card';
import Grid from 'app/ui/components/molecules/grid';
import { ResourceList } from 'app/types/graphql';

export type ResourceListGridProps = {
  resourceLists: ResourceList[];
};

const ResourceListGrid: React.FC<ResourceListGridProps> = ({ resourceLists }) => (
  <Grid elementsPerRow={3}>
    {resourceLists.map((resourceList, index) => (
      <ResourceListCard 
        key={`resource-list-grid-${resourceList.id || index}`}
        resourceList={resourceList}
      />
    ))}
  </Grid>
);

export default ResourceListGrid;
