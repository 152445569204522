import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  ${({ theme: { spacing } }) => `
    width: ${spacing(6)};
    height: ${spacing(6)};
  `}
`;

type Props = {
  className?: string,
};

const SvgGhostFill = ({ className = '' }: Props) => (
  <Svg viewBox="0 0 30 30" className={className}>
    <defs>
      <path id="ghost_svg__a" d="M24 21v-6h3v6h-3zM9 21v-6h3v6H9zm21 9V18c0-6.654-5.384-12-12-12-6.617 0-12 5.346-12 12v12h3v-3h3v3h4.5v-3h3v3H24v-3h3v3h3zM18 3c8.285 0 15 6.68 15 15v15H3V18C3 9.68 9.716 3 18 3z" />
    </defs>
    <g fill="none" fillRule="evenodd" opacity=".502" transform="translate(-3 -3)">
      <use fill="currentColor" xlinkHref="#ghost_svg__a" />
    </g>
  </Svg>
);

export default SvgGhostFill;
