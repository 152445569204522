import { useState, useEffect, useRef, useCallback } from 'react';
import { onSlideChanged } from 'app/hooks/use-timer/util';

export enum TimerMessageType {
  TIMER_PLAY,
  TIMER_PAUSE,
  TIMER_RESTART,
  TIMER_SYNC_STATE,
}

type TimerBroadcastMessage = {
  type: TimerMessageType,
  timerId: string,
  timeRemaining?: number,
  isRunning?: boolean,
};

type UseTimerProps = {
  timerId: string,
  initialDuration: number,
  autoStart?: boolean,
};

const useTimer = ({ timerId, initialDuration, autoStart = false }: UseTimerProps) => {
  const channel = new BroadcastChannel('timer_sync_channel');

  const [timeRemaining, setTimeRemaining] = useState(initialDuration);
  const [isRunning, setIsRunning] = useState(autoStart);
  const intervalRef = useRef<number | null>(null);

  const clearTimerInterval = useCallback(() => {
    if (intervalRef.current !== null) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  }, []);

  const startTimer = useCallback(() => {
    if (intervalRef.current === null) {
      intervalRef.current = window.setInterval(() => {
        setTimeRemaining(prev => {
          if (prev <= 0) {
            clearTimerInterval();
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
  }, [clearTimerInterval]);

  const reset = useCallback(() => {
    clearTimerInterval();
    setTimeRemaining(initialDuration);
    setIsRunning(false);
  }, [clearTimerInterval, initialDuration]);

  useEffect(() => (
    onSlideChanged(() => {
      reset();
    })
  ), []);

  const play = () => {
    setIsRunning(true);
    channel.postMessage({
      type: TimerMessageType.TIMER_PLAY,
      timerId,
    });
  };

  const pause = () => {
    setIsRunning(false);
    channel.postMessage({
      type: TimerMessageType.TIMER_PAUSE,
      timerId,
    });
  };

  const restart = () => {
    setTimeRemaining(initialDuration);
    channel.postMessage({
      type: TimerMessageType.TIMER_RESTART,
      timerId,
      timeRemaining: initialDuration,
    });
  };

  const syncState = useCallback(() => {
    channel.postMessage({
      type: TimerMessageType.TIMER_SYNC_STATE,
      timerId,
      timeRemaining,
      isRunning,
    });
  }, [timerId, timeRemaining, isRunning]);

  useEffect(() => {
    const handleMessage = (event: MessageEvent<TimerBroadcastMessage>) => {
      if (event.data && event.data.timerId === timerId) {
        switch (event.data.type) {
          case TimerMessageType.TIMER_PLAY:
            setIsRunning(true);
            break;
          case TimerMessageType.TIMER_PAUSE:
            setIsRunning(false);
            break;
          case TimerMessageType.TIMER_RESTART:
            setTimeRemaining(initialDuration);
            break;
          case TimerMessageType.TIMER_SYNC_STATE:
            if (event.data.timeRemaining !== undefined) {
              setTimeRemaining(event.data.timeRemaining);
            }
            if (event.data.isRunning !== undefined) {
              setIsRunning(event.data.isRunning);
            }
            break;
          default:
            break;
        }
      }
    };

    channel.addEventListener('message', handleMessage);

    syncState();

    return () => {
      channel.removeEventListener('message', handleMessage);
      clearTimerInterval();
    };
  }, [timerId, initialDuration, clearTimerInterval, syncState]);

  useEffect(() => {
    setTimeRemaining(initialDuration);
    if (autoStart) {
      setIsRunning(true);
    } else {
      setIsRunning(false);
    }
  }, [initialDuration, autoStart]);

  useEffect(() => {
    if (isRunning && timeRemaining > 0) {
      startTimer();
    } else {
      clearTimerInterval();
    }

    return () => clearTimerInterval();
  }, [isRunning, timeRemaining, startTimer, clearTimerInterval]);

  return {
    timeRemaining,
    isRunning,
    play,
    pause,
    restart,
    reset,
  };
};

export default useTimer;
