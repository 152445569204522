import styled from 'styled-components';

export const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
  z-index: 10;
`;

export const DropdownButton = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme: { colors } }) => colors.veryGray};
  color: ${({ theme: { colors } }) => colors.focoltone};
  padding: ${({ theme: { spacing } }) => `${spacing(2)} ${spacing(3)}`};
  border-radius: ${({ theme: { spacing } }) => spacing(4)};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 2rem;
`;

export const DropdownList = styled.ul`
  position: absolute;
  top: 2.75rem;
  left: -6rem;
  background-color: ${({ theme: { colors } }) => colors.veryGray};
  border-radius: ${({ theme: { spacing } }) => spacing(4)};
  overflow: hidden;
  margin: 0;
  padding: 0;
  list-style: none;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  width: 12rem;
`;

export const DropdownItem = styled.li`
  padding: ${({ theme: { spacing } }) => `${spacing(3)} ${spacing(4)}`};
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${({ theme: { typography } }) => typography.xsmall};

  &:hover {
    background-color: ${({ theme: { colors } }) => colors.focoltoneWithTransparencyHover};
  }

  &:not(:last-child) {
    border-bottom: 2px solid ${({ theme: { colors: { black } } }) => black};
  }
`;

export const Label = styled.span<{ $isOpen: boolean }>`
  color: ${({ $isOpen, theme: { colors: { focoltone, white } } }) => $isOpen ? focoltone : white};
`;
