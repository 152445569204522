import React from 'react';
import ResourceCard from 'app/ui/components/molecules/resource-card';
import Grid from 'app/ui/components/molecules/grid';
import { Resource } from 'app/types/graphql';

export type ResourceGridProps = {
  resources: Resource[];
  parentResourceListId?: string;
};

const ResourceGrid: React.FC<ResourceGridProps> = ({ resources, parentResourceListId }) => (
  <Grid elementsPerRow={2}>
    {resources.map((resource, index) => (
      <ResourceCard 
        key={`resource-grid-${resource.id || index}`}
        resource={resource}
        parentResourceListId={parentResourceListId}
      />
    ))}
  </Grid>
);

export default ResourceGrid;
