import React, { ReactNode } from 'react';
import styled from 'styled-components';

export type GridProps = {
  children: ReactNode;
  elementsPerRow?: number;
};

const GridContainer = styled.div<{ $elementsPerRow: number }>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(6)};
  width: 100%;
  overflow-x: hidden;
  
  ${({ theme: { media }, $elementsPerRow }) => `
    ${media.medium(() => `
      display: grid;
      grid-template-columns: repeat(${$elementsPerRow}, minmax(0, 1fr));
      box-sizing: border-box;
    `)}
  `};
`;

const CardWrapper = styled.div`
  height: 100%;
  display: flex;
  width: 100%;
  
  > * {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
  }
`;

const Grid: React.FC<GridProps> = ({ children, elementsPerRow = 3 }) => (
  <GridContainer $elementsPerRow={elementsPerRow}>
    {React.Children.map(children, (child, index) => (
      <CardWrapper key={`grid-item-${index}`}>
        {child}
      </CardWrapper>
    ))}
  </GridContainer>
);

export default Grid;
