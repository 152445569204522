import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  ${({ theme: { spacing } }) => `
    width: ${spacing(6)};
    height: ${spacing(6)};
  `}
`;

type Props = {
  className?: string,
};

const IntervalsDark = ({ className = '' }: Props) => (
  <Svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M2 0C0.895431 0 0 0.89543 0 2V22C0 23.1046 0.89543 24 2 24H22C23.1046 24 24 23.1046 24 22V2C24 0.895431 23.1046 0 22 0H2ZM17.8333 13.2727C17.8333 10.1096 15.2217 7.54545 12 7.54545C8.77834 7.54545 6.16667 10.1096 6.16667 13.2727C6.16667 16.4358 8.77834 19 12 19C15.2217 19 17.8333 16.4358 17.8333 13.2727ZM12 13.2727V8.81818C14.5057 8.81818 16.537 10.8125 16.537 13.2727H12ZM17.8333 6.90909C17.8333 6.55764 17.5431 6.27273 17.1852 6.27273C16.8272 6.27273 16.537 6.55764 16.537 6.90909C16.537 7.26054 16.8272 7.54545 17.1852 7.54545C17.5431 7.54545 17.8333 7.26054 17.8333 6.90909ZM13.9444 6.27273V5H10.0556V6.27273H13.9444Z" fill="black"/>
  </Svg>
);

export default IntervalsDark;
