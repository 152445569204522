import React from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import useConfig from 'app/config';

import Typography from 'app/ui/components/atoms/typography';
import ResourceListGrid from 'app/ui/components/molecules/resource-list-card/grid';
import Loading from 'app/ui/components/molecules/loading';

import { GetRootResourceList } from 'app/pages/resource-lists-home/root-resource-list.gql';
import { GetRootResourceListQuery, GetRootResourceListQueryVariables } from 'app/types/graphql';

const Container = styled.div`
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing(20)};
  margin-bottom: ${({ theme }) => theme.spacing(14)};
`;

const Title = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(6)};
`;

type MoreResourceContentProps = {
  limit?: number;
};

const MoreResourceContent: React.FC<MoreResourceContentProps> = ({ limit = 3 }) => {
  const { config } = useConfig();

  const { data, loading, error } = useQuery<
    GetRootResourceListQuery,
    GetRootResourceListQueryVariables
  >(GetRootResourceList, {
    variables: { resourceListId: config.REACT_APP_ROOT_RESOURCE_LIST_ID },
  });

  if (loading) {
    return <Loading />;
  }

  if (error || !data || !data.resourceListById || data.resourceListById.resourceLists?.length === 0) {
    return null;
  }

  return (
    <Container>
      <Title>
        <Typography variant="h3" color="white">
          More content
        </Typography>
      </Title>
      <ResourceListGrid resourceLists={data.resourceListById.resourceLists?.slice(0, limit) || []} />
    </Container>
  );
};

export default MoreResourceContent;
