import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Link } from 'react-router-dom';
import Typography from 'app/ui/components/atoms/typography';

import { Resource } from 'app/types/graphql';

export type ResourceCardProps = {
  resource: Resource;
  parentResourceListId?: string;
};

const CardLink = styled(Link)`
  text-decoration: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.veryGray};
  cursor: pointer;
  transition: 0.2s ease-in-out;

  &:hover {
    background: ${({ theme }) => theme.colors.focoltoneWithTransparencyHover};
    border: 1px solid ${({ theme }) => theme.colors.focoltone};
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  ${({ theme: { spacing } }) => `
    padding: ${spacing(6)};
    gap: ${spacing(4)};
  `};
`;

const ResourceCard: React.FC<ResourceCardProps> = ({
  resource: {
    id,
    title,
    content,
  },
  parentResourceListId,
}) => {
  const theme = useTheme();

  return (
    <CardLink to={`/resources/${id}?resourceListId=${parentResourceListId}`}>
      <Container>
        <ContentContainer>
          <Typography variant="h5" color={theme.colors.white}>
            {title}
          </Typography>
          {content && (
            <Typography variant="caption" color={theme.colors.lightGray}>
              {content}
            </Typography>
          )}
        </ContentContainer>
      </Container>
    </CardLink>
  );
};

export default ResourceCard;
