import React from 'react';
import styled from 'styled-components';

import { Theme } from 'app/providers/theme';
import Typography from 'app/ui/components/atoms/typography';

import {
  Exercise,
  LessonSection,
  SectionExercise,
  SectionExerciseGroup,
  LessonSection as WorkoutSection,
} from 'app/types/graphql';

import { indexToLetter } from 'app/pages/workout/util';
import { HandleSectionFocusChangeProps } from 'app/pages/workout';
import { lengthFromDurationSeconds, lengthFromExercise } from 'app/utils/duration-handler';
import SectionFormatIconWithTooltip from 'app/ui/components/atoms/icons/section-formats/icon-with-tooltip';

import ContentItemAreasWrapper, {
  ContentData,
  ContentItemData,
  GroupContentItemData,
} from 'app/ui/components/molecules/content-item-areas-wrapper';

const MobileSectionDuration = styled(Typography)`
  margin-bottom: 1.5rem;
  weight: bold;
`;

type SectionExerciseToContentItemDataArgs = {
  exercisePosition: string,
  sectionExercise: SectionExercise,
  selectedAlternatives?: Record<string, string>,
};

const exerciseToContentItemData = (exercise: Exercise) => ({
  title: exercise.name,
  originalExerciseId: exercise.id,
  content: exercise.description,
  video: exercise.video,
});

const sectionExerciseToContentItemData = (sectionExercise: SectionExercise) => ({
  extraContent: sectionExercise.description,
  additionalInfo: lengthFromExercise(sectionExercise),
  notes: sectionExercise.notes?.trim(),
});

const getContentItemDataFromSectionExercise = ({
  sectionExercise,
  selectedAlternatives,
}: SectionExerciseToContentItemDataArgs): ContentItemData => {
  const alternativeExercises = sectionExercise?.exercise?.alternativeExercises?.map((exercise) => ({
    id: exercise.id,
    ...(sectionExerciseToContentItemData(sectionExercise)),
    ...(exerciseToContentItemData(exercise)),
  })) || [];

  const selectedExerciseId = selectedAlternatives?.[sectionExercise.id];

  if (selectedExerciseId && alternativeExercises.length > 0) {
    const selectedExercise = alternativeExercises.find(exercise => exercise.id === selectedExerciseId);
    
    if (selectedExercise) {
      return {
        ...selectedExercise,
        alternativeExercises,
        id: sectionExercise.id,
      };
    }
  }

  return {
    id: sectionExercise.id,
    ...(sectionExerciseToContentItemData(sectionExercise)),
    ...(exerciseToContentItemData(sectionExercise.exercise)),
    alternativeExercises,
  };
};

type SectionExerciseGroupToContentItemDataArgs = {
  position: number,
  sectionExerciseGroup: SectionExerciseGroup,
  selectedAlternatives?: Record<string, string>,
};

const sectionExerciseGroupToContentItemData = ({
  sectionExerciseGroup,
  selectedAlternatives,
}: SectionExerciseGroupToContentItemDataArgs): GroupContentItemData => ({
  title: sectionExerciseGroup.name,
  content: sectionExerciseGroup.description,
  additionalInfo: lengthFromDurationSeconds(sectionExerciseGroup.duration || 0),
  id: sectionExerciseGroup.id,
  showTitle: sectionExerciseGroup.showTitle,
  sectionExercises: sectionExerciseGroup?.sectionExercises?.map((sectionExercise, index) => (
    getContentItemDataFromSectionExercise({ 
      sectionExercise, 
      exercisePosition: indexToLetter(index),
      selectedAlternatives,
    })
  )) || [],
  notes: sectionExerciseGroup?.notes?.trim(),
});

type WorkoutDataProps = {
  contentItemFocused?: string | null,
  handleContentItemClick?: (prop: HandleSectionFocusChangeProps) => void,
  isMobile?: boolean,
  theme?: Theme,
  dataOnly?: boolean,
  selectedAlternatives?: Record<string, string>,
};

type SectionsToDataArgs = WorkoutDataProps & {
  lessonSections: LessonSection[],
};

type SectionToDataArgs = WorkoutDataProps & {
  lessonSection: WorkoutSection,
};

export const sectionToData = ({
  lessonSection,
  theme,
  isMobile = false,
  dataOnly = false,
  contentItemFocused = null,
  handleContentItemClick = (() => {}),
  selectedAlternatives = {},
}: SectionToDataArgs): ContentData => {
  const sectionExerciseGroupsData = lessonSection?.sectionExerciseGroups
    ?.map((sectionExerciseGroup, index) => (
      sectionExerciseGroupToContentItemData({ 
        sectionExerciseGroup, 
        position: index + 1,
        selectedAlternatives,
      })
    )) || [];

  const duration = lengthFromDurationSeconds(lessonSection.duration);

  return {
    title: lessonSection.name,
    titleExtraContent: <SectionFormatIconWithTooltip
      format={lessonSection.format}
      isRotational={lessonSection.isRotational}
    />,
    content: isMobile && !dataOnly
      ? (
        <>
          <MobileSectionDuration
            variant="body2"
            color={theme?.colors.paragraphGray}
          >
            Section duration: <b>{duration.toLowerCase()}</b>
          </MobileSectionDuration>
          {lessonSection.description}
        </>
      )
      : lessonSection.description,
    notes: lessonSection.notes?.trim(),
    duration: lessonSection.duration,
    additionalInfo: duration,
    format: lessonSection.format,
    id: lessonSection.id,
    ...(dataOnly
      ? { groups: sectionExerciseGroupsData }
      : {
          contentItems: <ContentItemAreasWrapper
            items={sectionExerciseGroupsData}
            contentItemFocused={contentItemFocused}
            handleContentItemClick={handleContentItemClick}
          />
        }
    ),
  }
};

export const sectionsToData = ({
  lessonSections = [],
  contentItemFocused,
  handleContentItemClick,
  isMobile,
  theme,
  dataOnly = false,
  selectedAlternatives = {},
}: SectionsToDataArgs): ContentData[] => (
  (lessonSections).map((lessonSection) => sectionToData({
    lessonSection,
    contentItemFocused,
    handleContentItemClick,
    isMobile,
    theme,
    dataOnly,
    selectedAlternatives,
  }))
);
