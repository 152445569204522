import React from 'react';
import styled, { useTheme } from 'styled-components';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import GenericPage from 'app/ui/components/layouts/generic-page';

import PageTitle from 'app/ui/components/molecules/page-title';
import Error from 'app/ui/components/molecules/error';
import Loading from 'app/ui/components/molecules/loading';
import Typography from 'app/ui/components/atoms/typography';

import { GetResourceList } from 'app/pages/resource-list/resource-list.gql';
import { GetResourceListQuery, GetResourceListQueryVariables } from 'app/types/graphql';

import ResourceGrid from 'app/ui/components/molecules/resource-card/grid';
import ResourceListGrid from 'app/ui/components/molecules/resource-list-card/grid';

import { Container } from 'app/ui/components/atoms/resource-list/container';
import { TitleWrapper } from 'app/ui/components/atoms/resource-list/title-wrapper';
import { ResourceListDescription } from 'app/ui/components/atoms/resource-list/description';

type ResourceListContentProps = {
  resourceListId: string;
};

const ResourceListContent: React.FC<ResourceListContentProps> = ({ resourceListId }) => {
  const theme = useTheme();
  
  const { data, loading, error } = useQuery<
    GetResourceListQuery, GetResourceListQueryVariables
  >(GetResourceList, {
    variables: { resourceListId },
  });

  if (loading) {
    return <Loading />;
  }

  if (error || !data || !data.resourceListById) {
    return <Error />;
  }

  const resourceList = data.resourceListById;

  return (
    <GenericPage>
      <Container>
        <TitleWrapper>
          <PageTitle>
            <Typography variant="h3" color={theme.colors.white}>
              {resourceList.title}
            </Typography>
          </PageTitle>
          {resourceList.content && (
            <ResourceListDescription variant="body2" color={theme.colors.lightGray}>
              {resourceList.content}
            </ResourceListDescription>
          )}
        </TitleWrapper>
        {resourceList?.resourceLists && resourceList?.resourceLists.length > 0 && (
          <ResourceListGrid resourceLists={resourceList.resourceLists} />
        )}
        {resourceList?.resources && resourceList?.resources.length > 0 && (
          <ResourceGrid resources={resourceList.resources} parentResourceListId={resourceList.id} />
        )}
      </Container>
    </GenericPage>
  );
};

const ResourceListPage: React.FC = () => {
  const { id: resourceListId } = useParams();
  
  if (!resourceListId) {
    return <Error />;
  }
  
  return <ResourceListContent resourceListId={resourceListId} />;
};

export default ResourceListPage;
