import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  ${({ theme: { spacing } }) => `
    width: ${spacing(6)};
    height: ${spacing(6)};
  `}
`;

type Props = {
  color: string,
  className?: string,
};

const BarbellPlate = ({ color, className = '' }: Props) => (
  <Svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM14.4355 5.43737C12.8846 4.86179 11.18 4.85408 9.6239 5.41561C8.06783 5.97715 6.76092 7.07164 5.93496 8.50497C5.10899 9.9383 4.81734 11.6178 5.11174 13.2457C5.40613 14.8736 6.26755 16.3446 7.54321 17.3979C8.81887 18.4511 10.4263 19.0186 12.0805 18.9995C13.7347 18.9805 15.3287 18.3763 16.5798 17.2939C17.8309 16.2116 18.6582 14.7212 18.9151 13.087C19.172 11.4527 18.8418 9.78034 17.983 8.36639L17.3813 8.73182C18.1537 10.0036 18.4507 11.5078 18.2196 12.9776C17.9886 14.4475 17.2445 15.788 16.1192 16.7615C14.9939 17.735 13.5602 18.2785 12.0724 18.2956C10.5846 18.3127 9.1388 17.8023 7.99143 16.855C6.84407 15.9077 6.06928 14.5846 5.80449 13.1204C5.53971 11.6563 5.80202 10.1456 6.54492 8.85647C7.28782 7.56729 8.46329 6.58287 9.86286 6.07781C11.2624 5.57275 12.7957 5.57968 14.1906 6.09738L14.4355 5.43737ZM12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z" fill={color}/>
  </Svg>
);

export default BarbellPlate;
