export enum TimerResetEventType {
  SLIDE_CHANGED
}

export type TimerResetEvent = {
  type: TimerResetEventType,
  slideIndex: number,
  timestamp: number,
}

const timerResetChannel = new BroadcastChannel('timer_reset_channel');

export const notifySlideChanged = (slideIndex: number) => {
  timerResetChannel.postMessage({
    type: TimerResetEventType.SLIDE_CHANGED,
    slideIndex,
    timestamp: Date.now(),
  });
};

export const onSlideChanged = (callback: (slideIndex: number) => void) => {
  const handleMessage = (event: MessageEvent<TimerResetEvent>) => {
    if (event.data && event.data.type === TimerResetEventType.SLIDE_CHANGED) {
      callback(event.data.slideIndex);
    }
  };

  timerResetChannel.addEventListener('message', handleMessage);

  return () => {
    timerResetChannel.removeEventListener('message', handleMessage);
  };
};
