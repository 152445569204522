import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  ${({ theme: { spacing } }) => `
    width: ${spacing(6)};
    height: ${spacing(6)};
  `}
`;

type Props = {
  color: string,
  className?: string,
};

const Rope = ({ color, className = '' }: Props) => (
  <Svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.61407 6.29891C4.61407 5.58154 5.21833 5 5.96373 5H20.6503C21.3957 5 22 5.58154 22 6.29891C22 7.01628 21.3957 7.59782 20.6503 7.59782H5.96373C5.21833 7.59782 4.61407 7.01628 4.61407 6.29891Z" fill={color}/>
    <path d="M4.61407 9.36626C4.61407 8.64889 5.21833 8.06735 5.96373 8.06735H20.6503C21.3957 8.06735 22 8.64889 22 9.36626C22 10.0836 21.3957 10.6652 20.6503 10.6652H5.96373C5.21833 10.6652 4.61407 10.0836 4.61407 9.36626Z" fill={color}/>
    <path d="M12.23 13.6455C12.3896 13.6555 12.5438 13.6644 12.6927 13.673C13.5626 13.7232 14.2521 13.763 14.7959 13.9658C15.0671 14.0669 15.2086 14.1805 15.2919 14.2934C15.3725 14.4026 15.4799 14.6208 15.4799 15.0741C15.4799 15.5026 15.3872 15.7102 15.0796 15.9068C14.6287 16.1949 13.5914 16.5201 11.3348 16.5201H9.19259V19H11.3348C13.7205 19 15.3998 18.679 16.5051 17.9726C17.7537 17.1746 18.0567 16.0393 18.0567 15.0741C18.0567 14.2248 17.8473 13.4735 17.3928 12.8576C16.9411 12.2453 16.3291 11.878 15.7268 11.6534C14.7685 11.2961 13.6016 11.235 12.7677 11.1914C12.6356 11.1845 12.5118 11.178 12.3986 11.1709L12.23 13.6455Z" fill={color}/>
    <path d="M8.50228 19V16.5201H3V19H8.50228Z" fill={color}/>
    <path d="M12.23 13.6455C12.3896 13.6555 12.5438 13.6644 12.6927 13.673C13.5626 13.7232 14.2521 13.763 14.7959 13.9658C15.0671 14.0669 15.2086 14.1805 15.2919 14.2934C15.3725 14.4026 15.4799 14.6208 15.4799 15.0741C15.4799 15.5026 15.3872 15.7102 15.0796 15.9068C14.6287 16.1949 13.5914 16.5201 11.3348 16.5201H9.19259V19H11.3348C13.7205 19 15.3998 18.679 16.5051 17.9726C17.7537 17.1746 18.0567 16.0393 18.0567 15.0741C18.0567 14.2248 17.8473 13.4735 17.3928 12.8576C16.9411 12.2453 16.3291 11.878 15.7268 11.6534C14.7685 11.2961 13.6016 11.235 12.7677 11.1914C12.6356 11.1845 12.5118 11.178 12.3986 11.1709L12.23 13.6455Z" fill={color}/>
    <path d="M8.50228 19V16.5201H3V19H8.50228Z" fill={color}/>
    <path d="M18.7511 13.648H20.7131C21.4238 13.648 22 13.0935 22 12.4094C22 11.7254 21.4238 11.1709 20.7131 11.1709H16.7708C17.2333 11.4256 17.6877 11.7766 18.0645 12.2636C18.3912 12.6858 18.6152 13.1526 18.7511 13.648Z" fill={color}/>
    <path d="M12.2322 13.648L12.3931 11.1709H5.901C5.19025 11.1709 4.61407 11.7254 4.61407 12.4094C4.61407 13.0935 5.19025 13.648 5.901 13.648H12.2322Z" fill={color}/>
  </Svg>

);

export default Rope;
