import { useMemo } from 'react';
import { useAppState } from 'state';

export const useAlternativeSelections = () => {
  const alternativeSelections = useAppState(({ alternativeExercises }) => alternativeExercises.selections);

  return useMemo(() => {
    return alternativeSelections.reduce((acc, selection) => {
      acc[selection.sectionExerciseId] = selection.exerciseId;
      return acc;
    }, {} as Record<string, string>);
  }, [alternativeSelections]);
};
