import React from 'react';
import styled from 'styled-components';

export interface StepsProps {
  totalSteps: number;
  currentStep: number;
}

const StepWrapper = styled.div`
  width: 100%;
  margin: ${({ theme: { spacing } }) => `0 0 ${spacing(2)}`};
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

const StepLine = styled.div<{ $isActive: boolean }>`
  height: 0.25rem;
  border-radius: 0.25rem;
  background-color: ${({ $isActive: isActive, theme }) => (isActive ? theme.colors.focoltone : theme.colors.veryGray)};
  flex-grow: 1;
  flex-shrink: 1;
  margin: ${({ theme: { spacing } }) => `0 ${spacing(2)}`};
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
`;

export const Steps: React.FC<StepsProps> = ({ totalSteps, currentStep }) => {
  return (
    <StepWrapper>
      {Array.from({ length: totalSteps }, (_, index) => (
        <StepLine key={index} $isActive={index < currentStep} />
      ))}
    </StepWrapper>
  );
};
