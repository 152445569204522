import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

import Typography from 'app/ui/components/atoms/typography';
import GenericPage from 'app/ui/components/layouts/generic-page';
import Accordion from 'app/ui/components/molecules/accordion';
import PageTitle from 'app/ui/components/molecules/page-title';
import Changelog, { ChangelogEntry } from 'app/ui/components/molecules/changelog';

import { useFAQData } from 'app/pages/faq/data';

const Header = styled(Typography)`
  margin: 2rem 0 0.5rem;
`;

const AccordionWrapper = styled.div`
  margin: 0 auto;
`;

const RECENT_UPDATES: ChangelogEntry[] = [
  {
    version: '1.7',
    localeKey: 'releases.versions.1_7',
  },
  {
    version: '1.6',
    localeKey: 'releases.versions.1_6',
  },
  {
    version: '1.5',
    localeKey: 'releases.versions.1_5',
  },
  {
    version: '1.4',
    localeKey: 'releases.versions.1_4',
  },
  {
    version: '1.3',
    localeKey: 'releases.versions.1_3',
  },
  {
    version: '1.2',
    localeKey: 'releases.versions.1_2',
  },
  {
    version: '1.1',
    localeKey: 'releases.versions.1_1',
  }
];
const KNOWN_ISSUES: ChangelogEntry[] = [];
const COMING_SOON: string[] = [];

const Faq: React.FC = () => {
  const intl = useIntl();

  const releasesHeader = intl.formatMessage({ id: 'releases.header' });
  const releasesCopy = intl.formatMessage({ id: 'releases.copy' });

  const faqHeader = intl.formatMessage({ id: 'faq.header' });

  return (
    <GenericPage>
      <AccordionWrapper>
        <PageTitle>
          <Header variant="h3">{releasesHeader}</Header>
          <Typography variant="body2">
            {releasesCopy}
          </Typography>
        </PageTitle>
        <Changelog recentUpdates={RECENT_UPDATES} knownIssues={KNOWN_ISSUES} comingSoon={COMING_SOON} />
        <Header variant="h3">{faqHeader}</Header>
        <Accordion data={useFAQData()} />
      </AccordionWrapper>
    </GenericPage>
  );
};

export default Faq;
