import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  ${({ theme: { spacing } }) => `
    width: ${spacing(4)};
    height: ${spacing(4)};
  `}
`;

type Props = {
  color: string,
  className?: string,
};

const RoundTick = ({ className = '', color }: Props) => (
  <Svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM4.31565 8.0969L5.26899 7.14357L7.17485 9.04944L10.5124 5.71187L11.4658 6.6652L8.12819 10.0028L8.12898 10.0036L7.17565 10.9569L4.31565 8.0969Z" fill={color} />
  </Svg>
);

export default RoundTick;
