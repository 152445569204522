import React, { useState, useEffect, useCallback, useMemo } from 'react';
import styled, { useTheme } from 'styled-components';
import { useQuery, useMutation } from '@apollo/client';
import { useLocation, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useAppState } from 'state';

import { useAnalytics } from 'app/utils/analytics/provider';
import useConfig from 'app/config';

import Typography from 'app/ui/components/atoms/typography';
import Accordion from 'app/ui/components/molecules/accordion';
import Error from 'app/ui/components/molecules/error';
import Loading from 'app/ui/components/molecules/loading';
import VideoPlayer from 'app/ui/components/molecules/video-component';
import Breadcrumb from 'app/ui/components/molecules/breadcrumb';
import WorkoutActions from 'app/ui/components/molecules/workout-actions';

import GenericPage from 'app/ui/components/layouts/generic-page';

import { EquipmentIconWithName, getEquipmentIconWithName } from 'app/ui/components/atoms/icons/equipment/util';
import { lengthFromDurationSeconds } from 'app/utils/duration-handler';

import IntensityIcon from 'app/assets/intensity-icon.svg';
import DurationIcon from 'app/assets/duration-icon.svg';
import PresentationIcon from 'app/assets/presentation-icon.svg';

import { WorkoutPage as WORKOUT } from 'app/pages/workout/workout.gql';
import {
  WorkoutPageQuery,
  WorkoutPageQueryVariables,
  SaveLessonByIdMutation,
  SaveLessonByIdMutationVariables,
  UnsaveLessonByIdMutation,
  UnsaveLessonByIdMutationVariables,
  CompleteLessonByIdMutation,
  CompleteLessonByIdMutationVariables,
  UncompleteLessonByIdMutation,
  UncompleteLessonByIdMutationVariables,
} from 'app/types/graphql';

import { ContentData } from 'app/ui/components/molecules/content-item-areas-wrapper';

import {
  VideoData,
  WorkoutType,
  getVideoData,
  flattenSectionsToExercises,
} from 'app/pages/workout/util';

import useTitle from 'app/hooks/use-title';
import DotDividedElementsWrapper from 'app/ui/components/molecules/dot-divided-elements-wrapper';

import { CompleteLessonById, UncompleteLessonById } from 'app/pages/workout/complete.gql';
import { SaveLessonById, UnsaveLessonById } from 'app/pages/workout/save.gql';

import { GACustomEvents } from 'app/utils/analytics';
import { sectionToData } from 'app/pages/workout/util/data';
import { mapLessonToSlides } from 'app/ui/components/molecules/whiteboard-slide/util';

import Slideshow from 'app/ui/components/molecules/slideshow';
import { useAlternativeSelections } from 'app/hooks/use-alternative-exercises';

export type HandleSectionFocusChangeProps = {
  sectionKey?: string | null,
  sectionExerciseKey?: string | null,
  exerciseKey?: string | null,
};

type WorkoutPageProps = {
  workout: WorkoutType,
  contentItemFocused: string | null,
  focusedAccordionSection: string | null,
  setFocusedSection: (props: HandleSectionFocusChangeProps) => void,
  exercisePicked: string | null,
};

type WorkoutPageWrapperProps = {
  lessonId: string,
};

type WorkoutBreakdownProps = {
  workout: WorkoutType,
  isSaved: boolean,
  saveAction: () => void,
  unSaveAction: () => void,
  isCompleted: boolean,
  completeAction: () => void,
  unCompleteAction: () => void,
};

type InitialSectionKeys = {
  sectionKey: string,
  sectionExerciseKey: string | null,
};

type WorkoutAccordionProps = {
  data: ContentData[],
  initialSelectedKeys: InitialSectionKeys,
  updateVideoData: (key: string | null) => void,
  isSaved: boolean,
  saveAction: () => void,
  unSaveAction: () => void,
  isCompleted: boolean,
  completeAction: () => void,
  unCompleteAction: () => void,
};

type WorkoutMediaProps = {
  videoData: VideoData | null,
  workoutImageUrl: string,
  isSticky: boolean,
  autoPlay?: boolean,
};

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${({ theme: { spacing } }) => `
    padding: 0 0 ${spacing(8)} 0;
  `}

  ${({ theme: { media, spacing } }) => `${media.medium(() => `
      display: flex;
      padding: ${spacing(8)};
    `)}
  `};

  ${({ theme: { media, spacing } }) => `${media.large(() => `
      width: 95%;
      padding: 0 0 ${spacing(8)} 0;
    `)}
  `};
`;

const WorkoutTopLayer = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme: { media } }) => `${media.medium(() => `
      max-width: 60%;
      flex: 3;
    `)}
  `};
`;

const Breakdown = styled.div`
  word-break: break-word;
`;

const BreakdownWrapper = styled(Breakdown)`
  display: flex;
  flex-direction: column;
`;

const Video = styled.div`
  width: 80%;

  ${({ theme: { spacing } }) => `
    margin-bottom: ${spacing(8)};
  `}

  ${({ theme: { media } }) => `${media.medium(() => `
      margin: 0 auto;
    `)}
  `};
`;

const Title = styled(Typography)`
  text-transform: uppercase;
`;

const Description = styled(Typography)`
  margin-bottom: 1rem;

  ${({ theme: { media, spacing } }) => `${media.medium(() => `
      padding-right: ${spacing(4)};
    `)}
  `};
`;

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;

  ${({ theme: { spacing } }) => `
    gap: ${spacing(4)};
  `}

  label {
    text-transform: uppercase;
  }
`;

const EquipmentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  ${({ theme: { spacing } }) => `
    gap: ${spacing(2)};
    margin-bottom: ${spacing(6)}
  `}
`;

const DetailWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;

  ${({ theme: { spacing } }) => `
    gap: ${spacing(2)};
    margin: ${spacing(4)} 0 ${spacing(6)};
  `}

  label {
    text-transform: uppercase;
  }
`;

const Icon = styled.div`
  ${({ theme: { spacing } }) => `
    width: ${spacing(4)};
    height: ${spacing(4)};
  `}

  align-items: baseline;

  img {
    width: 100%;
    height: 100%;
  }
`;

const StyledAccordion = styled(Accordion)`
  width: 100%;
  padding: 0 0.1rem;

  ${({ theme: { media } }) => `${media.medium(() => `
      z-index: 10;
      position: relative;
    `)}
  `};
`;

const StyledVideoPlayer = styled(VideoPlayer)`
  aspect-ratio: 1;
  ${({ theme: { media } }) => `${media.medium(() => `
    aspect-ratio: 0.75;
    `)}
  `};
`;

const StyledImage = styled.img`
  max-width: 100%;
  object-fit: cover;
  aspect-ratio: 1;
  ${({ theme: { spacing } }) => `border-radius: ${spacing(2)};`}
  ${({ theme: { media } }) => `${media.medium(() => `
      aspect-ratio: 0.75;
    `)}
  `};
`;

const WorkoutMediaWrapper = styled.div<{ $isSticky: boolean }>`
  width: 100%;
  ${({ $isSticky }) => ($isSticky && 'position: sticky;')}
  top: 4.75em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: fit-content;
  z-index: 2;

  ${({ theme: { colors } }) => `background: ${colors.black};`}

  ${({ theme: { media } }) => `${media.medium(() => `
      flex: 2;
      justify-content: flex-end;
      background: transparent;
      position: sticky;
    `)}
  `};
`;

const DesktopStructure = styled.div`
  display: none;
  ${({ theme: { media } }) => `${media.medium(() => `
      display: flex;
      flex-direction: row;
    `)}
  `};
`;

const MobileStructure = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme: { media } }) => `${media.medium(() => `
      display: none;
    `)}
  `};
`;

const AccordionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const PresentationButton = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(2)};
  border-radius: ${({ theme }) => theme.spacing(8)};
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black};
  width: 100%;
  margin: ${({ theme }) => `${theme.spacing(4)} 0 ${theme.spacing(6)}`};
  padding: ${({ theme }) => `${theme.spacing(2)} 0`};
`;

const StyledWorkoutActions = styled(WorkoutActions)`
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

const lessonToWorkout = (lesson: any): WorkoutType => ({
  ...lesson,
  duration: lengthFromDurationSeconds(lesson.duration),
  imageUrl: lesson.image.url || lesson.image.signedUrl || '',
  intensity: `RPE ${lesson.intensity}`,
  equipment: lesson.equipment || [],
});

const WorkoutBreakdown: React.FC<WorkoutBreakdownProps> = ({
  workout,
  isSaved,
  saveAction,
  unSaveAction,
  isCompleted,
  completeAction,
  unCompleteAction,
}) => {
  const theme = useTheme();
  const intl = useIntl();
  const location = useLocation();

  const { config } = useConfig();
  const isWhiteboardEnabled = config.REACT_APP_WHITEBOARD_ENABLED;

  const selectedAlternatives = useAlternativeSelections();

  const queryParams = new URLSearchParams(location.search);
  const isPresenterView = queryParams.get('isPresenterView') !== 'false';
  const isSlideshowOn = queryParams.get('isSlideshowOn') === 'true';

  const [slides, setSlides] = useState<React.JSX.Element[]>(
    mapLessonToSlides({ workout, isPresenterView, selectedAlternatives })
  );

  const [showWhiteboard, setShowWhiteboard] = useState(isSlideshowOn);

  const equipmentLabel = intl.formatMessage({ id: 'lesson.labels.equipment' });
  const openCoachingViewLabel = intl.formatMessage({ id: 'whiteboard.open' });

  const handlePresentationButtonClick = () => {
    setSlides(mapLessonToSlides({ workout, isPresenterView, selectedAlternatives }));
    setShowWhiteboard(true);
  };

  return (
    <Breakdown>
      <Title variant='h2'>{workout.name}</Title>
      <DetailsWrapper>
        <DetailWrapper>
          <Icon>
            <img src={IntensityIcon} alt="Intensity Icon" />
          </Icon>
          <Typography variant="label">{workout.intensity}</Typography>
        </DetailWrapper>
        <DetailWrapper>
          <Icon>
            <img src={DurationIcon} alt="Duration Icon" />
          </Icon>
          <Typography variant="label">{workout.duration}</Typography>
        </DetailWrapper>
      </DetailsWrapper>
      {
        workout.equipment.length > 0 && (
          <EquipmentWrapper>
            <Typography variant='h6'>{equipmentLabel}: </Typography>
            {
              <DotDividedElementsWrapper
                elements={
                  workout.equipment
                    .map((eq) => (
                      getEquipmentIconWithName({
                        name: eq.name,
                        color: theme.colors.white,
                      })
                    ))
                    .filter((icon) => !!icon)
                    .map((details) => (details && (
                      <EquipmentIconWithName
                          icon={details.icon}
                          name={details.name}
                          color={theme.colors.white}
                        />
                      )
                    ))
                }
                color={theme.colors.white}
              />
            }
          </EquipmentWrapper>
        )
      }
      <Description variant='body2' color={theme.colors.paragraphGray}>{workout.description}</Description>
      <StyledWorkoutActions
        isSaved={isSaved}
        isCompleted={isCompleted}
        saveAction={saveAction}
        unSaveAction={unSaveAction}
        completeAction={completeAction}
        unCompleteAction={unCompleteAction}
      />
      {isWhiteboardEnabled && (
        <PresentationButton onClick={handlePresentationButtonClick}>
          <img src={PresentationIcon} alt="Presentation Icon" />
          {openCoachingViewLabel}
        </PresentationButton>
      )}

      {isWhiteboardEnabled && showWhiteboard && (
        <Slideshow
          isPresenterView={isPresenterView}
          slides={slides}
          onEndWhiteboard={() => setShowWhiteboard(false)}
        />
      )}
    </Breakdown>
  );
};

const WorkoutMedia: React.FC<WorkoutMediaProps> = ({
  videoData,
  workoutImageUrl,
  isSticky,
  autoPlay = true,
}: WorkoutMediaProps) => (
  <WorkoutMediaWrapper $isSticky={isSticky}>
    <Video>
      {
        videoData ? (
          <StyledVideoPlayer
            duration={videoData.duration}
            src={videoData.src}
            title={videoData.name}
            thumbnail={videoData.thumbnail}
            key={videoData.key}
            loop
            className="workoutVideoPlayer"
            autoPlay={autoPlay}
          />
        )
        : <StyledImage src={workoutImageUrl} />
      }
    </Video>
  </WorkoutMediaWrapper>
)

const WorkoutAccordion: React.FC<WorkoutAccordionProps> = ({
  data,
  initialSelectedKeys,
  updateVideoData,
  isSaved,
  isCompleted,
  saveAction,
  unSaveAction,
  completeAction,
  unCompleteAction,
}: WorkoutAccordionProps) => (
  <AccordionWrapper>
    <StyledAccordion
      data={data}
      className='workoutAccordion'
      initialSelectedKey={initialSelectedKeys.sectionKey}
      onClickEmit={updateVideoData}
    />
    <WorkoutActions
      isSaved={isSaved}
      isCompleted={isCompleted}
      saveAction={saveAction}
      unSaveAction={unSaveAction}
      completeAction={completeAction}
      unCompleteAction={unCompleteAction}
    />
  </AccordionWrapper>
);

const WorkoutPage: React.FC<WorkoutPageProps> = ({
  workout,
  contentItemFocused,
  focusedAccordionSection,
  exercisePicked,
  setFocusedSection,
}: WorkoutPageProps) => {
  useTitle(workout.name);

  const analytics = useAnalytics();

  useEffect(() => {
    if (analytics) {
      analytics.trackEvent({
        event: GACustomEvents.LESSON_VIEW,
        metadata: {
          lessonId: workout.id,
        },
      });
    }
  }, [workout.id]);

  const userId = useAppState(({ auth }) => auth.userId);

  const theme = useTheme();

  const isMobile = useMemo(
    () => /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
    [navigator.userAgent],
  );

  const allExercises = flattenSectionsToExercises(workout.sections);

  const videoDataFromAccordion = getVideoData({
    focusedContentItem: contentItemFocused,
    exercises: allExercises,
    exercisePicked,
  });

  const [videoData, setVideoData] = useState<VideoData | null>(videoDataFromAccordion);

  const updateVideoData = useCallback((key: string | null) => (
    setFocusedSection({ sectionKey: key, sectionExerciseKey: null })
  ), []);

  const mainSection = workout?.sections?.find((section) => section.isMain);
  const initialSelectedKeys: InitialSectionKeys = { 
    sectionKey: mainSection?.id,
    sectionExerciseKey: null,
  };

  useEffect(() => {
    setFocusedSection(initialSelectedKeys);
  }, []);

  useEffect(() => {
    setVideoData(videoDataFromAccordion);
  }, [focusedAccordionSection, contentItemFocused, exercisePicked]);

  const accordionData = workout.sections?.map((lessonSection) => sectionToData({
    lessonSection,
    contentItemFocused,
    handleContentItemClick: setFocusedSection,
    isMobile,
    theme,
  })) || [];

  const [isSaved, setIsSaved] = useState(workout.isSaved);

  const [saveLesson] = useMutation<
    SaveLessonByIdMutation,
    SaveLessonByIdMutationVariables
  >(SaveLessonById, {
    onError: () => {
      setIsSaved(false);
    },
    onCompleted: () => {
      setIsSaved(true);
    }
  });

  const [unSaveLesson] = useMutation<
    UnsaveLessonByIdMutation,
    UnsaveLessonByIdMutationVariables
  >(UnsaveLessonById, {
    onError: () => {
      setIsSaved(true);
    },
    onCompleted: () => {
      setIsSaved(false);
    }
  });

  const [isCompleted, setIsCompleted] = useState(workout.isCompleted);

  const [completeLesson] = useMutation<
    CompleteLessonByIdMutation,
    CompleteLessonByIdMutationVariables
  >(CompleteLessonById, {
    onError: () => {
      setIsCompleted(false);
    },
    onCompleted: () => {
      setIsCompleted(true);
    }
  });

  const [unCompleteLesson] = useMutation<
    UncompleteLessonByIdMutation,
    UncompleteLessonByIdMutationVariables
  >(UncompleteLessonById, {
    onError: () => {
      setIsCompleted(true);
    },
    onCompleted: () => {
      setIsCompleted(false);
    }
  });

  if (!userId) {
    return <Error />;
  }

  const handleSaveLesson = async () => {
    saveLesson({ variables: { id: workout.id, input: { user: { id: userId } } } })
  };

  const handleUnsaveLesson = async () => (
    unSaveLesson({ variables: { id: workout.id, input: { user: { id: userId } } } })
  );

  const handleCompleteLesson = async () => {
    completeLesson({ variables: { id: workout.id, input: { user: { id: userId } } } })
  };

  const handleUncompleteLesson = async () => (
    unCompleteLesson({ variables: { id: workout.id, input: { user: { id: userId } } } })
  );

  const breakdown = (
    <WorkoutBreakdown
      workout={workout}
      isSaved={isSaved}
      saveAction={handleSaveLesson}
      unSaveAction={handleUnsaveLesson}
      isCompleted={isCompleted}
      completeAction={handleCompleteLesson}
      unCompleteAction={handleUncompleteLesson}
    />
  );

  const accordion = (
    <WorkoutAccordion
      data={accordionData}
      initialSelectedKeys={initialSelectedKeys}
      updateVideoData={updateVideoData}
      isSaved={isSaved}
      saveAction={handleSaveLesson}
      unSaveAction={handleUnsaveLesson}
      isCompleted={isCompleted}
      completeAction={handleCompleteLesson}
      unCompleteAction={handleUncompleteLesson}
    />
  );

  const media = <WorkoutMedia
    videoData={videoData}
    workoutImageUrl={workout.imageUrl}
    isSticky={!!contentItemFocused}
    autoPlay={!isMobile}
  />;

  return (
    <GenericPage>
      <ContentWrapper>
        <Breadcrumb contentTitle={workout.name} />
        <DesktopStructure>
          <WorkoutTopLayer>
            <BreakdownWrapper>
              {breakdown}
            </BreakdownWrapper>
            {accordion}
          </WorkoutTopLayer>
          {media}
        </DesktopStructure>
        <MobileStructure>
          <WorkoutTopLayer>
              <BreakdownWrapper>
                {breakdown}
              </BreakdownWrapper>
            </WorkoutTopLayer>
            {media}
            {accordion}
        </MobileStructure>
      </ContentWrapper>
    </GenericPage>
  );
};

const WorkoutPageWrapper: React.FC<WorkoutPageWrapperProps> = ({ lessonId }: WorkoutPageWrapperProps) => {
  const { data, loading, error } = useQuery<WorkoutPageQuery, WorkoutPageQueryVariables>(
    WORKOUT,
    { variables: { id: lessonId } },
  );

  const [focusedAccordionSection, setFocusedAccordionSection] = useState<string | null>(null);
  const [contentItemFocused, setContentItemFocused] = useState<string | null>(null);
  const [exercisePicked, setExercisePicked] = useState<string | null>(null);

  if (loading) {
    return <Loading />;
  }

  if (error || !data || !data.lessonById) {
    return <Error />;
  }

  const workout = lessonToWorkout(data.lessonById);

  const handleSectionFocusChange = ({
    sectionKey,
    sectionExerciseKey,
    exerciseKey,
  }: HandleSectionFocusChangeProps) => {
    if (typeof sectionKey !== 'undefined') {
      setFocusedAccordionSection(sectionKey);
    }

    if (typeof sectionExerciseKey !== 'undefined') {
      setContentItemFocused(sectionExerciseKey);
    }

    if (typeof exerciseKey !== 'undefined') {
      setExercisePicked(exerciseKey);
    }
  };

  return (
    <WorkoutPage
      workout={workout}
      contentItemFocused={contentItemFocused}
      focusedAccordionSection={focusedAccordionSection}
      setFocusedSection={handleSectionFocusChange}
      exercisePicked={exercisePicked}
    />
  );
};

const Workout: React.FC = () => {
  const { id: lessonId } = useParams();

  if (!lessonId) {
    return <Error />;
  }

  return <WorkoutPageWrapper lessonId={lessonId} />;
};

export default Workout;
