import React from 'react';
import styled from 'styled-components';
import Typography from 'app/ui/components/atoms/typography';

export type RadioBoxProps = {
  id: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isChecked?: boolean;
};

const RadioContainer = styled.label<{ $isChecked: boolean }>`
  display: flex;
  justify-content: center;
  width: auto;
  height: auto;
  cursor: pointer;
  transition: background-color 0.3s;
  border: 2px solid;
  margin: -2px 0 0;
  ${({ theme: { colors, spacing }, $isChecked: isChecked }) => `
    border-color: ${isChecked ? colors.focoltone : colors.blackSwan};
    padding: ${spacing(5)};
    z-index: ${isChecked ? 5 : 1};
  `}
`;

// Hidden Checkbox
const HiddenRadio = styled.input.attrs({ type: 'radio' })`
  opacity: 0;
  position: absolute;
  width: 0;
  height: 0;
`;

const RadioBox: React.FC<RadioBoxProps> = ({ id, value, onChange, isChecked = false }) => {
  return (
    <RadioContainer $isChecked={isChecked}>
      <HiddenRadio checked={isChecked} onChange={onChange} value={id} />
      <Typography variant="body2">{value}</Typography>
    </RadioContainer>
  );
};

export default RadioBox;
