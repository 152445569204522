import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  ${({ theme: { spacing } }) => `
    width: ${spacing(5)};
    height: ${spacing(5)};
  `}
`;

type Props = {
  color: string,
  className?: string,
};

const WorldIcon = ({ className = '', color }: Props) => (
  <Svg className={className} xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 22" fill="none">
    <path strokeWidth="0.25" fillRule="evenodd" clipRule="evenodd" d="M2.05493 10H6.05009C6.2743 7.23681 7.1991 4.58442 8.72571 2.28983C5.16881 3.21597 2.46801 6.26324 2.05493 10ZM11 2.5508C9.33032 4.70194 8.30879 7.28683 8.05759 10H13.9424C13.6912 7.28683 12.6697 4.70194 11 2.5508ZM13.9424 12C13.6912 14.7132 12.6697 17.2981 11 19.4492C9.33032 17.2981 8.30879 14.7132 8.05759 12H13.9424ZM6.05009 12H2.05493C2.46801 15.7368 5.16881 18.784 8.72571 19.7102C7.1991 17.4156 6.2743 14.7632 6.05009 12ZM13.2743 19.7102C14.8009 17.4156 15.7257 14.7632 15.9499 12H19.9451C19.532 15.7368 16.8312 18.784 13.2743 19.7102ZM19.9451 10H15.9499C15.7257 7.23681 14.8009 4.58442 13.2743 2.28983C16.8312 3.21597 19.532 6.26324 19.9451 10ZM0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11Z" fill={color} stroke={color} />
  </Svg>
);

export default WorldIcon;
