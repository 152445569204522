import React, { useMemo } from 'react';
import styled, { useTheme } from 'styled-components';

import { ContentData } from 'app/ui/components/molecules/content-item-areas-wrapper';

import Typography from 'app/ui/components/atoms/typography';
import GroupBreakdown from 'app/ui/components/molecules/whiteboard-slide/group-breakdown';

import SlideTimer from 'app/ui/components/molecules/slide-timer';

type AudienceLessonSectionBreakdownProps = {
  sectionData: ContentData,
  isSmallVariant?: boolean,
  isOverviewMode?: boolean,
};

const BreakdownWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const SectionTitleArea = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.focoltone};
  padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(4)}`};
  margin: ${({ theme }) => theme.spacing(4)} 0;
  text-align: center;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ContentWrapper = styled.div<{ $isSmallVariant?: boolean }>`
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: ${({ theme, $isSmallVariant }) => $isSmallVariant ? theme.spacing(2) : 0};
  margin-top: ${({ theme }) => theme.spacing(3)};
  max-width: calc(100% - ${({ theme }) => theme.spacing(6)});
  overflow: hidden;

  ${({ theme: { media } }) => `${media.large(() => `
        flex-wrap: wrap;
    `)}
  `};
`;

const Zone = styled.div<{ $isSmallVariant?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(4.5)};

  ${({ theme: { media }, $isSmallVariant }) => `${media.large(() => `
        width: ${$isSmallVariant ? '100%' : '50%'};
    `)}
  `};
`;

const SlideInfo = styled(Typography)`
  font-weight: ${({ theme }) => theme.typography.bold};
  margin-top: ${({ theme }) => theme.spacing(1)};
`;

const AudienceLessonSectionBreakdown: React.FC<AudienceLessonSectionBreakdownProps> = ({
  sectionData,
  isSmallVariant,
  isOverviewMode,
}) => {
  const theme = useTheme();

  const applySmallVariant = isSmallVariant || isOverviewMode;

  const textVariant = applySmallVariant ? 'body3' : 'whiteboard1';

  const timerId = useMemo(() => `section-${sectionData.id}-timer`, [sectionData]);
  const duration = sectionData.duration || 0;

  return (
    <>
      <BreakdownWrapper>
        <SectionTitleArea>
          <SlideInfo variant={textVariant} color={theme.colors.black}>{sectionData.title}</SlideInfo>
          <SlideInfo variant={textVariant} color={theme.colors.black}>{sectionData.additionalInfo}</SlideInfo>
        </SectionTitleArea>

        {!isOverviewMode && (
          <SlideInfo variant={textVariant} color={theme.colors.white}>
            {sectionData.content}
          </SlideInfo>
        )}

        <ContentWrapper $isSmallVariant={applySmallVariant}>
          {sectionData?.groups?.map((group, groupIndex) => (
            <Zone key={`zone-${group.id}`} $isSmallVariant={applySmallVariant}>
              <GroupBreakdown
                group={group}
                index={groupIndex + 1}
                key={`group-${groupIndex}`}
                isSingleGroup={(sectionData?.groups?.length || 0) <= 1}
                isSmallVariant={applySmallVariant}
                isOverviewMode={isOverviewMode}
              />
            </Zone>
          ))}
        </ContentWrapper>
      </BreakdownWrapper>

      {!isOverviewMode && (
        <SlideTimer
          startSeconds={duration}
          timerId={timerId}
        />
      )}
    </>
  );
};

export default AudienceLessonSectionBreakdown;
