import React from 'react';
import styled from 'styled-components';
import Typography from 'app/ui/components/atoms/typography';

export type PillProps = {
  id: string | number;
  name: string;
  isChecked?: boolean;
  disabled: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const CheckboxContainer = styled.label<{ $isChecked: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: auto;
  height: 40px;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 1.5rem;
  margin: 0.2rem;

  ${({ theme: { spacing }}) => `
    padding: ${spacing(3)} ${spacing(5)};
  `};

  ${({ theme: { colors, spacing, media }, $isChecked: isChecked }) => `
    padding: ${spacing(2)} ${spacing(4)};
    background-color: ${isChecked ? colors.focoltone : colors.veryGray};
    p { color: ${isChecked ? colors.black : colors.white}; }
    ${media.medium(
      () => `
        padding: ${spacing(4.5)} ${spacing(5)};
      `,
    )}
  `}
`;

// Hidden Checkbox
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  opacity: 0;
  position: absolute;
  width: 0;
  height: 0;
`;

const Pill: React.FC<PillProps> = ({ id: value, name, isChecked = false, disabled = false, onChange }) => (
  <CheckboxContainer $isChecked={isChecked}>
    <HiddenCheckbox value={value} checked={isChecked} onChange={onChange} disabled={disabled} />
    <Typography variant="body3">{name}</Typography>
  </CheckboxContainer>
);

export default Pill;
