import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  ${({ theme: { spacing } }) => `
    width: ${spacing(6)};
    height: ${spacing(6)};
  `}
`;

type Props = {
  color: string,
  className?: string,
};

const EmptyBookmark = ({ color, className = '' }: Props) => (
  <Svg className={className} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.0003 21.3333L25.3337 28V4H6.66699V28L16.0003 21.3333Z" stroke={color} strokeWidth="2" strokeLinecap="round"/>
  </Svg>
);

export default EmptyBookmark;
