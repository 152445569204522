import React, { FC } from 'react';
import { Tooltip } from 'react-tooltip';

import styled, { useTheme } from 'styled-components';
import { Theme } from 'app/providers/theme';

import Typography from 'app/ui/components/atoms/typography';

import useSectionFormatDetails, { OtherSectionIcons } from 'app/pages/workout/util/icons';
import { LessonSectionFormat } from 'app/types/graphql';

interface SectionFormatIconWithTooltipProps {
  format: LessonSectionFormat,
  isRotational: boolean,
}

const getTooltipStyle = ({ spacing, colors }: Theme) => ({
  display: 'flex',
  flexDirection: 'column' as 'column',
  padding: spacing(4.5),
  borderRadius: spacing(2),
  maxWidth: '500px',
  backgroundColor: colors.blackSwan,
});

const TooltipTitle = styled(Typography)`
  font-weight: bold;
  text-transform: uppercase;
  width: 100%;
`;

const IconsArea = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${({ theme: { spacing } }) => spacing(1)};
  gap: ${({ theme: { spacing } }) => spacing(2)};

  .react-tooltip {
    position: absolute !important;
    z-index: 100;
  }
`;

const SectionFormatIconWithTooltip: FC<SectionFormatIconWithTooltipProps> = ({ format, isRotational }) => {
  const theme = useTheme();

  const { icon, name, description } = useSectionFormatDetails(format);
  const tooltipId = `tooltip-${name}`;

  const {
    icon: rotationalIcon,
    name: rotationalName,
    description: rotationalDescription,
  } = useSectionFormatDetails(OtherSectionIcons.ROTATIONAL);

  const rotationalTooltipId = `tooltip-${rotationalName}`;

  return (
    <IconsArea>
      {name.length > 0 && (
        <>
          <div data-tooltip-id={tooltipId}>
            {icon}
          </div>
          <Tooltip
            id={tooltipId}
            style={getTooltipStyle(theme)}
            border={`2px solid ${theme.colors.devilsGray}`}
            place='bottom'
            opacity={1}
          >
            <TooltipTitle variant='caption'>{name}</TooltipTitle>
            <Typography variant='caption'>{description}</Typography>
          </Tooltip>
        </>
      )}
      {isRotational && (
        <>
          <div data-tooltip-id={rotationalTooltipId}>
            {rotationalIcon}
          </div>
          <Tooltip
            id={rotationalTooltipId}
            style={getTooltipStyle(theme)}
            border={`2px solid ${theme.colors.devilsGray}`}
            place='bottom'
            opacity={1}
          >
            <TooltipTitle variant='caption'>{rotationalName}</TooltipTitle>
            <Typography variant='caption'>{rotationalDescription}</Typography>
          </Tooltip>
        </>
      )}
    </IconsArea>
  );
};

export default SectionFormatIconWithTooltip;
