import React from 'react';
import styled from 'styled-components';

import CountdownTimer from 'app/ui/components/molecules/countdown';
import { SlideTimerProps } from 'app/ui/components/molecules/slide-timer';
import TimerControls from '../countdown/controls';

export interface SlideTimerWithControlsProps extends SlideTimerProps {
  initialDuration?: number,
}

const TimerContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.faqGrey};
  border-radius: ${({ theme }) => theme.spacing(4)};
  padding: ${({ theme }) => theme.spacing(6)};
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 4rem;
`;

const TitleText = styled.h2`
  color: ${({ theme }) => theme.colors.white};
  font-size: 24px;
  margin: 0 0 ${({ theme }) => theme.spacing(4)} 0;
  font-weight: ${({ theme }) => theme.typography.bold};
`;

const TimerContentContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(4)};
`;

const TimerArea = styled.div`
  background-color: ${({ theme }) => theme.colors.greenWithTransparency};
  border: 2px solid ${({ theme }) => theme.colors.green};
  border-radius: ${({ theme }) => theme.spacing(3)};
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(6)};
`;

const WorkoutLabel = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 20px;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  opacity: 0.9;
`;

const StyledCountdownTimer = styled(CountdownTimer)`
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
  line-height: 1;

  ${({ theme: { media } }) => `${media.medium(() => `
      font-size: 5rem;
    `)}
  `};
`;

const VerticalControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(4)};
  width: 5rem;
`;

const SlideTimerWithControls: React.FC<SlideTimerWithControlsProps> = ({
  startSeconds,
  timerId,
  isPresenterView = true,
  initialDuration,
}) => (
  <TimerContainer>
    <TitleText>Timer control</TitleText>
    <TimerContentContainer>
      <TimerArea>
        <WorkoutLabel>Workout</WorkoutLabel>
        <StyledCountdownTimer 
          startSeconds={startSeconds} 
          timerId={timerId}
          isPresenterView={isPresenterView}
        />
      </TimerArea>
      <VerticalControlsContainer>
        <TimerControls
          timerId={timerId}
          initialDuration={initialDuration || startSeconds}
        />
      </VerticalControlsContainer>
    </TimerContentContainer>
  </TimerContainer>
);

export default SlideTimerWithControls;
