export const routes = {
  SIGNIN: '/sign-in',
  FORGOT_PASSWORD: '/forgot-password',
  SET_PASSWORD: '/set-password',
  PROFILE: '/profile',
  FAQ: '/faq',
  WEEKLY_WORKOUTS: '/workouts',
  WORKOUTS: '/browse',
  WORKOUT: '/workouts/:id',
  ONBOARDING_GYM_TYPE: '/onboarding/gym-type',
  ONBOARDING_GYM_EQUIPMENT: '/onboarding/gym-equipment',
  HOME: '/',
  RESOURCES: '/resources',
  RESOURCE: '/resources/:id',
  RESOURCE_LISTS_HOME: '/resource-lists-home',
  RESOURCE_LIST: '/resource-lists/:id',
};
