import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  ${({ theme: { spacing } }) => `
    width: ${spacing(4)};
    height: ${spacing(4)};
  `}
`;

type Props = {
  color: string,
  className?: string,
};

const Tick = ({ className = '', color = 'white' }: Props) => (
  <Svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 12L9.5 16.5L20 6" stroke={color} stroke-width="2"/>
  </Svg>
);

export default Tick;
