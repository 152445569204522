import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  ${({ theme: { spacing } }) => `
    width: ${spacing(6)};
    height: ${spacing(6)};
  `}
`;

type Props = {
  className?: string,
};

const TabataDark = ({ className = '' }: Props) => (
  <Svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M0 2C0 0.89543 0.895431 0 2 0H22C23.1046 0 24 0.895431 24 2V22C24 23.1046 23.1046 24 22 24H2C0.89543 24 0 23.1046 0 22V2ZM18.0221 17.2294H13.1042C12.9385 17.2294 12.8042 17.0951 12.8042 16.9294V7.27485C12.8042 7.10917 12.9385 6.97485 13.1042 6.97485H17.6823C19.623 6.97485 20.9897 8.00937 20.9897 9.70839C20.9897 10.5171 20.6666 11.176 20.0757 11.6739C19.8799 11.839 19.9082 12.1873 20.1282 12.3184C20.9367 12.8002 21.2842 13.5272 21.2842 14.4279C21.2842 15.9834 20.0685 17.2294 18.0221 17.2294ZM15.8171 10.677C15.8171 10.8427 15.9515 10.977 16.1171 10.977H16.9951C17.5766 10.977 17.8862 10.5768 17.8862 10.1539C17.8862 9.7537 17.5388 9.34593 16.9347 9.34593H16.1171C15.9515 9.34593 15.8171 9.48025 15.8171 9.64593V10.677ZM15.8171 14.5357C15.8171 14.7014 15.9515 14.8357 16.1171 14.8357H17.1009C17.7201 14.8357 18.075 14.4279 18.075 14.0428C18.075 13.6048 17.7276 13.2122 17.1009 13.2122H16.1171C15.9515 13.2122 15.8171 13.3465 15.8171 13.5122V14.5357ZM2.71579 9.13655C2.71579 9.30223 2.8501 9.43655 3.01579 9.43655H5.33811C5.50379 9.43655 5.63811 9.57086 5.63811 9.73655V16.9294C5.63811 17.0951 5.77242 17.2294 5.93811 17.2294H8.35104C8.51673 17.2294 8.65104 17.0951 8.65104 16.9294V9.73655C8.65104 9.57086 8.78536 9.43655 8.95104 9.43655H11.3489C11.5146 9.43655 11.6489 9.30223 11.6489 9.13655V7.27485C11.6489 7.10917 11.5146 6.97485 11.3489 6.97485H3.01579C2.8501 6.97485 2.71579 7.10917 2.71579 7.27485V9.13655Z" fill="black"/>
  </Svg>
);

export default TabataDark;
