import React from 'react';

import { sectionsToData } from 'app/pages/workout/util/data';

import LessonSlideWrapper from 'app/ui/components/molecules/whiteboard-slide/lesson-slide-wrapper';

import { WorkoutType } from 'app/pages/workout/util';
import { LessonSectionFormat } from 'app/types/graphql';
import LessonSectionBreakdown from 'app/ui/components/molecules/whiteboard-slide/section-breakdown';
import LessonOverview from 'app/ui/components/molecules/whiteboard-slide/lesson-overview';

type MapLessonToSlidesProps = {
  workout: WorkoutType,
  isPresenterView?: boolean,
  isOverviewIncluded?: boolean,
  selectedAlternatives?: Record<string, string>,
};

export const mapLessonToSlides = ({
  workout,
  isPresenterView,
  isOverviewIncluded = true,
  selectedAlternatives = {},
}: MapLessonToSlidesProps) => {
  const lessonSections = (workout?.sections || []).filter((section) => section.format !== LessonSectionFormat.COOLDOWN);

  const sectionsData = sectionsToData({ lessonSections, dataOnly: true, selectedAlternatives });
  const totalSlides = isOverviewIncluded ? sectionsData.length + 1 : sectionsData.length;

  const slideProps = {
    lessonTitle: workout.name,
    totalSlides,
    isPresenterView,
  };

  const slides = [];
  let startIndex = 1;

  if (isOverviewIncluded) {
    slides.push(
      <LessonSlideWrapper {...slideProps} slideIndex={startIndex}>
        <LessonOverview
          sectionsData={sectionsData.filter(({ format }) => format !== LessonSectionFormat.COOLDOWN)}
        />
      </LessonSlideWrapper>
    );
    startIndex += 1;
  }

  slides.push(
    ...sectionsData.map((sectionData, index) => (
      <LessonSlideWrapper
        {...slideProps}
        slideIndex={startIndex + index}
      >
        <LessonSectionBreakdown
          sectionData={sectionData}
          isPresenterView={isPresenterView}
        />
      </LessonSlideWrapper>
    ))
  );

  return slides;
};
