import React, { useMemo } from 'react';
import styled, { useTheme } from 'styled-components';

import { ContentData } from 'app/ui/components/molecules/content-item-areas-wrapper';

import Typography from 'app/ui/components/atoms/typography';
import SlideTimerWithControls from 'app/ui/components/molecules/slide-timer/with-controls';

type PresenterLessonSectionBreakdownProps = {
  sectionData: ContentData,
  isSmallVariant?: boolean,
  isOverviewMode?: boolean,
};

const BreakdownWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column-reverse;
  
  ${({ theme: { media } }) => media.large(() => `
    flex-direction: row;
  `)}
`;

const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  padding-bottom: ${({ theme }) => theme.spacing(16)};

  ${({ theme: { media } }) => media.large(() => `
    width: 50%;
    padding-bottom: 0;
  `)}
`;

const SectionTitleArea = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.focoltone};
  padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(4)}`};
  margin: ${({ theme }) => theme.spacing(4)} 0;
  text-align: center;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const SlideInfo = styled(Typography)`
  font-weight: ${({ theme }) => theme.typography.bold};
  margin-top: ${({ theme }) => theme.spacing(1)};
`;

const SlideNoteArea = styled.div`
  width: 100%;
  margin: ${({ theme }) => `${theme.spacing(3)} 0`};
  z-index: 101;
  max-height: 80vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding-right: ${({ theme }) => theme.spacing(2)};
`;

const SlideNote = styled(Typography)`
  ul {
    list-style-position: inside;
    padding-left: ${({ theme }) => theme.spacing(8)};
  }

  li {
    text-indent: -1.4rem;
  }
`;

const TimerContainer = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.spacing(4)};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PresenterLessonSectionBreakdown: React.FC<PresenterLessonSectionBreakdownProps> = ({
  sectionData,
  isSmallVariant = true,
  isOverviewMode,
}) => {
  const theme = useTheme();

  const textVariant = isSmallVariant ? 'body3' : 'whiteboard1';

  const timerId = useMemo(() => `section-${sectionData.id}-timer`, [sectionData]);
  const duration = sectionData.duration || 0;

  return (
    <BreakdownWrapper>
      <Column>
        <SectionTitleArea>
          <SlideInfo variant={textVariant} color={theme.colors.black}>{sectionData.title}</SlideInfo>
          <SlideInfo variant={textVariant} color={theme.colors.black}>{sectionData.additionalInfo}</SlideInfo>
        </SectionTitleArea>

        {!isOverviewMode && (
          <SlideNoteArea>
            <Typography variant={textVariant} color={theme.colors.white}>Notes: </Typography>
            {sectionData?.notes && sectionData.notes.trim().length > 0 ? (
              <SlideNote variant={textVariant} color={theme.colors.white} htmlContent={sectionData.notes}></SlideNote>
            ) : (
              <SlideNote variant={textVariant} color={theme.colors.white}>No coaching notes</SlideNote>
            )}
          </SlideNoteArea>
        )}
      </Column>
      <Column>
        {!isOverviewMode && (
          <TimerContainer>
            <SlideTimerWithControls
              startSeconds={duration}
              initialDuration={duration}
              timerId={timerId}
              isPresenterView={true}
            />
          </TimerContainer>
        )}
      </Column>
    </BreakdownWrapper>
  );
};

export default PresenterLessonSectionBreakdown;
