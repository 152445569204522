import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  ${({ theme: { spacing } }) => `
    width: ${spacing(6)};
    height: ${spacing(6)};
  `}
`;

type Props = {
  color: string,
  className?: string,
};

const SuspensionTrainer = ({ color, className = '' }: Props) => (
  <Svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.375 5.68182C2.375 5.30526 2.6828 5 3.0625 5C3.4422 5 3.75 5.30526 3.75 5.68182V11.1364C3.75 11.5129 3.4422 11.8182 3.0625 11.8182C2.6828 11.8182 2.375 11.5129 2.375 11.1364V5.68182Z" fill={color}/>
    <path d="M18.875 11.1364C18.875 12.266 17.9516 13.1818 16.8125 13.1818C15.6734 13.1818 14.75 12.266 14.75 11.1364C14.75 10.0067 15.6734 9.09091 16.8125 9.09091C17.9516 9.09091 18.875 10.0067 18.875 11.1364Z" fill={color}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M17.5 17.2353C17.2743 17.26 17.0449 17.2727 16.8125 17.2727C15.339 17.2727 13.9858 16.7619 12.9231 15.9091L3.75 15.9091V20H1V19.3636C1 18.962 1.32832 18.6364 1.73333 18.6364H2.375V15.2727C2.375 14.8711 2.70332 14.5455 3.10833 14.5455L11.6669 14.5455C11.0088 13.5704 10.625 12.3978 10.625 11.1364C10.625 10.3919 10.7587 9.67849 11.0035 9.01831C10.779 8.9061 10.625 8.67543 10.625 8.40909C10.625 8.03253 10.9328 7.72727 11.3125 7.72727C11.4228 7.72727 11.527 7.75303 11.6194 7.7988C12.7224 6.11422 14.6363 5 16.8125 5C20.2298 5 23 7.74734 23 11.1364C23 13.8082 21.2782 16.0812 18.875 16.9236V18.6364H19.5167C19.9217 18.6364 20.25 18.962 20.25 19.3636V20H17.5V17.2353ZM20.0667 11.1364C20.0667 12.9187 18.6097 14.3636 16.8125 14.3636C15.0153 14.3636 13.5583 12.9187 13.5583 11.1364C13.5583 9.35399 15.0153 7.90909 16.8125 7.90909C18.6097 7.90909 20.0667 9.35399 20.0667 11.1364Z" fill={color}/>
    <path d="M6.5 8.40909C6.5 8.78565 6.1922 9.09091 5.8125 9.09091C5.4328 9.09091 5.125 8.78565 5.125 8.40909C5.125 8.03253 5.4328 7.72727 5.8125 7.72727C6.1922 7.72727 6.5 8.03253 6.5 8.40909Z" fill={color}/>
    <path d="M8.5625 9.09091C8.9422 9.09091 9.25 8.78565 9.25 8.40909C9.25 8.03253 8.9422 7.72727 8.5625 7.72727C8.1828 7.72727 7.875 8.03253 7.875 8.40909C7.875 8.78565 8.1828 9.09091 8.5625 9.09091Z" fill={color}/>
  </Svg>
);

export default SuspensionTrainer;
