import React from 'react';
import styled from 'styled-components';

import Typography from 'app/ui/components/atoms/typography';
import DropDown, { Option } from 'app/ui/components/atoms/drop-down';

import { Fieldset } from 'app/ui/components/molecules/field-set-styled';

export type DropDownOnChangeEvent = (selectedOption: Option) => void;

export interface DropDownFieldSetProps {
  options: Option[];
  label: string;
  onChange: DropDownOnChangeEvent;
  noValueText: string;
  value?: string;
  disabled?: boolean;
}

const DropDownFieldset = styled(Fieldset)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 1rem 0;
  ${({ theme: { spacing, media } }) => `
    ${media.medium(
      () => `
        gap: ${spacing(2)};
      `,
    )}
  `}
`;

export const DropDownFieldSet: React.FC<DropDownFieldSetProps> = ({ options, label, onChange, value, disabled = false, noValueText }) => {
  return (
    <Fieldset>
      <Typography variant="body2">{label}</Typography>
      <DropDownFieldset>
        <DropDown options={options} onChange={onChange} value={value} disabled={disabled} noValueText={noValueText} />
      </DropDownFieldset>
    </Fieldset>
  );
};
