import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  ${({ theme: { spacing } }) => `
    width: ${spacing(6)};
    height: ${spacing(6)};
  `}
`;

type Props = {
  color: string,
  className?: string,
};

const Sled = ({ color, className = '' }: Props) => (
  <Svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 4H5V18H10.8544V16.9879H7.22778C6.95164 16.9879 6.72778 16.7641 6.72778 16.4879V15.4879C6.72778 15.2118 6.95164 14.9879 7.22778 14.9879H10.8544V14.1714H7.22778C6.95164 14.1714 6.72778 13.9475 6.72778 13.6714V12.6714C6.72778 12.3952 6.95164 12.1714 7.22778 12.1714H10.8544V11.2902H7.22778C6.95164 11.2902 6.72778 11.0664 6.72778 10.7902V9.79023C6.72778 9.51409 6.95164 9.29023 7.22778 9.29023H10.8853C10.9954 8.8576 11.3875 8.53761 11.8544 8.53761C12.3213 8.53761 12.7135 8.8576 12.8236 9.29023H16.2278C16.5039 9.29023 16.7278 9.51409 16.7278 9.79023V10.7902C16.7278 11.0664 16.5039 11.2902 16.2278 11.2902H12.8544V12.1714H16.2278C16.5039 12.1714 16.7278 12.3952 16.7278 12.6714V13.6714C16.7278 13.9475 16.5039 14.1714 16.2278 14.1714H12.8544V14.9879H16.2278C16.5039 14.9879 16.7278 15.2118 16.7278 15.4879V16.4879C16.7278 16.7641 16.5039 16.9879 16.2278 16.9879H12.8544V18H19V15.3846H21V18H23V19C23 19.5523 22.5523 20 22 20H2C1.44772 20 1 19.5523 1 19V18H3V4Z" fill={color}/>
  </Svg>
);

export default Sled;
