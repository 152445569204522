import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  ${({ theme: { spacing } }) => `
    width: ${spacing(6)};
    height: ${spacing(6)};
  `}
`;

type Props = {
  color: string,
  className?: string,
};

const Airbike = ({ color, className = '' }: Props) => (
  <Svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M15.125 1.35851C14.8914 1.72266 14.9943 2.20918 15.3548 2.44517L18.9944 4.82789L17.4364 8.29728C16.9083 8.15011 16.3521 8.0715 15.7778 8.0715C15.6271 8.0715 15.4777 8.07691 15.3297 8.08754L14.3322 3.04905C14.1867 2.31453 13.5483 1.78581 12.8068 1.78581H8.77777C8.34821 1.78581 7.99999 2.13758 7.99999 2.57152C7.99999 3.00546 8.34821 3.35723 8.77777 3.35723H12.8068L13.8041 8.39431C11.6675 9.1157 10.0472 10.9762 9.64965 13.2621L5.88187 9.45591L6.70721 9.25581C8.1957 8.89492 8.11321 6.72837 6.60167 6.48406L2.62119 5.84069C1.77057 5.7032 1 6.36698 1 7.23721V8.84564C1 9.76023 1.84636 10.4343 2.72672 10.2209L4.10777 9.88604L9.65659 15.4915C9.69599 15.7098 9.74656 15.9243 9.80767 16.1341L4.5667 21.4286H3.33333V23H6.44444V21.754L10.4882 17.669C11.5859 19.4542 13.5444 20.6429 15.7778 20.6429C16.3149 20.6429 16.8361 20.5741 17.3333 20.4448V23H20.4444V21.4286H18.8889V19.802C20.7487 18.7151 22 16.6838 22 14.3572C22 12.0238 20.7414 9.98735 18.8726 8.9029L20.411 5.47711C20.7291 4.76886 20.4865 3.93211 19.8404 3.50916L16.2007 1.12644C15.8402 0.890452 15.3586 0.994352 15.125 1.35851ZM14.4242 11.5266C13.384 12.0351 12.6667 13.1115 12.6667 14.3572C12.6667 16.0929 14.0596 17.5 15.7778 17.5C17.496 17.5 18.8889 16.0929 18.8889 14.3572C18.8889 12.6797 17.5879 11.3092 15.9497 11.2191L16.2761 12.8681C16.8908 13.078 17.3333 13.6654 17.3333 14.3572C17.3333 15.2251 16.6369 15.9286 15.7778 15.9286C14.9187 15.9286 14.2222 15.2251 14.2222 14.3572C14.2222 13.8868 14.4268 13.4648 14.7509 13.1768L14.4242 11.5266Z" fill={color} />
  </Svg>
  
);

export default Airbike;
