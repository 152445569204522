import styled from 'styled-components';

import HyroxLogo from 'app/ui/components/atoms/hyrox-logo';
import PoweredByFiit from 'app/ui/components/atoms/powered-by-fiit';

export const LogosWrapper = styled.div<{ $isPresenterView?: boolean }>`
  display: none;
  flex-direction: row;
  align-items: flex-start;
  position: absolute;
  ${({ $isPresenterView }) => $isPresenterView ? 'top: 0;' : 'bottom: 0;'};
  right: 0;
  gap: ${({ theme }) => theme.spacing(14)};
  padding: ${({ theme }) => theme.spacing(8)};

  ${({ theme: { media } }) => `${media.medium(() => `
      display: flex;
    `)}
  `};
`;

export const LargeHyroxLogo = styled(HyroxLogo)`
  width: 8rem;
  height: auto;
`;

export const LargePoweredByFiit = styled(PoweredByFiit)`
  width: 12rem;
  height: auto;
`;
