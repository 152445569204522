import styled from 'styled-components';

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing(6)};
  align-items: baseline;
  margin-bottom: ${({ theme }) => theme.spacing(2)};

  ${({ theme: { media } }) => `${media.medium(() => `
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 0;
    `)}
  `};
`;

export default TitleWrapper;
