import styled from 'styled-components';

const SlideHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing(4)};
  padding-bottom: ${({ theme }) => theme.spacing(4)};

  ${({ theme: { media } }) => `${media.medium(() => `
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    `)}
  `};
`;

export default SlideHeader;
