import React from 'react';
import styled, { useTheme } from 'styled-components';

import Typography from 'app/ui/components/atoms/typography';
import { FormattedMessage, useIntl } from 'react-intl';

const FAQSection = styled.div`
  padding: ${({ theme: { spacing } }) => `${spacing(4)} 0 ${spacing(2)}`};
`;

const List = styled.ul`
  padding: ${({ theme: { spacing } }) => `${spacing(2)} ${spacing(4)}`};
`;

const ListItem = styled.li`
  margin-bottom: 0.25rem;
`;

const Paragraph = styled(Typography)`
  margin: ${({ theme: { spacing } }) => `${spacing(2)} 0`};
`;

export const useFAQData = () => {
  const theme = useTheme();
  const intl = useIntl();

  return [
    {
      id: 'faq-1',
      title: `1. ${intl.formatMessage({ id: 'faq.questions.q1.title' })}`,
      contentItems: (
        <FAQSection>
          <Typography variant="body2" color={theme.colors.paragraphGray}>
            <FormattedMessage id="faq.questions.q1.content.copy" />
          </Typography>
          <List>
            <ListItem>
              <Typography variant="body2" color={theme.colors.paragraphGray}>
                <FormattedMessage
                    id="faq.questions.q1.content.listItem1"
                    values={{
                      b: (chunks) => <strong>{chunks}</strong>
                    }}
                  />
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2" color={theme.colors.paragraphGray}>
                <FormattedMessage
                  id="faq.questions.q1.content.listItem2"
                  values={{
                    b: (chunks) => <strong>{chunks}</strong>
                  }}
                />
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2" color={theme.colors.paragraphGray}>
                <FormattedMessage
                  id="faq.questions.q1.content.listItem3"
                  values={{
                    b: (chunks) => <strong>{chunks}</strong>
                  }}
                />
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2" color={theme.colors.paragraphGray}>
                <FormattedMessage
                  id="faq.questions.q1.content.listItem4"
                  values={{
                    b: (chunks) => <strong>{chunks}</strong>
                  }}
                />
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2" color={theme.colors.paragraphGray}>
                <FormattedMessage
                  id="faq.questions.q1.content.listItem5"
                  values={{
                    b: (chunks) => <strong>{chunks}</strong>
                  }}
                />
              </Typography>
            </ListItem>
          </List>
          <Paragraph variant="body2" color={theme.colors.paragraphGray}>
            <FormattedMessage
              id="faq.questions.q1.content.paragraph1"
            />
          </Paragraph>
          <Paragraph variant="body2" color={theme.colors.paragraphGray}>
            <FormattedMessage
              id="faq.questions.q1.content.paragraph2"
            />
          </Paragraph>
        </FAQSection>
      ),
    },
    {
      id: 'faq-2',
      title: `2. ${intl.formatMessage({ id: 'faq.questions.q2.title' })}`,
      contentItems: (
        <FAQSection>
          <Paragraph variant="body2" color={theme.colors.paragraphGray}>
            <FormattedMessage
              id="faq.questions.q2.content.paragraph1"
            />
          </Paragraph>
          <Paragraph variant="body2" color={theme.colors.paragraphGray}>
            <FormattedMessage
              id="faq.questions.q2.content.paragraph2"
              values={{
                b: (chunks) => <strong>{chunks}</strong>
              }}
            />
          </Paragraph>
          <Paragraph variant="body2" color={theme.colors.paragraphGray}>
            <FormattedMessage
              id="faq.questions.q2.content.paragraph3"
              values={{
                b: (chunks) => <strong>{chunks}</strong>
              }}
            />
          </Paragraph>
        </FAQSection>
      ),
    },
    {
      id: 'faq-3',
      title: `3. ${intl.formatMessage({ id: 'faq.questions.q3.title' })}`,
      contentItems: (
        <FAQSection>
          <Typography variant="body2" color={theme.colors.paragraphGray}>
            <FormattedMessage
              id="faq.questions.q3.content.paragraph1"
              values={{
                b: (chunks) => <strong>{chunks}</strong>
              }}
            />
          </Typography>
        </FAQSection>
      ),
    },
    {
      id: 'faq-4',
      title: `4. ${intl.formatMessage({ id: 'faq.questions.q4.title' })}`,
      contentItems: (
        <FAQSection>
          <Typography variant="body2" color={theme.colors.paragraphGray}>
            <FormattedMessage
              id="faq.questions.q4.content.paragraph1"
              values={{
                b: (chunks) => <strong>{chunks}</strong>
              }}
            />
          </Typography>
        </FAQSection>
      ),
    },
    {
      id: 'faq-5',
      title: `5. ${intl.formatMessage({ id: 'faq.questions.q5.title' })}`,
      contentItems: (
        <FAQSection>
          <Typography variant="body2" color={theme.colors.paragraphGray}>
            <FormattedMessage
              id="faq.questions.q5.content.paragraph1"
              values={{
                b: (chunks) => <strong>{chunks}</strong>
              }}
            />
          </Typography>
        </FAQSection>
      ),
    },
    {
      id: 'faq-6',
      title: `6. ${intl.formatMessage({ id: 'faq.questions.q6.title' })}`,
      contentItems: (
        <FAQSection>
          <Typography variant="body2" color={theme.colors.paragraphGray}>
            <FormattedMessage
              id="faq.questions.q6.content.paragraph1"
              values={{
                b: (chunks) => <strong>{chunks}</strong>
              }}
            />
          </Typography>
        </FAQSection>
      ),
    },
    {
      id: 'faq-7',
      title: `7. ${intl.formatMessage({ id: 'faq.questions.q7.title' })}`,
      contentItems: (
        <FAQSection>
          <Typography variant="body2" color={theme.colors.paragraphGray}>
            <FormattedMessage
              id="faq.questions.q7.content.paragraph1"
              values={{
                b: (chunks) => <strong>{chunks}</strong>
              }}
            />
          </Typography>
        </FAQSection>
      ),
    },
    {
      id: 'faq-8',
      title: `8. ${intl.formatMessage({ id: 'faq.questions.q8.title' })}`,
      contentItems: (
        <FAQSection>
          <Typography variant="body2" color={theme.colors.paragraphGray}>
            <FormattedMessage id="faq.questions.q8.content.intro" />
          </Typography>
          <List>
            <ListItem>
              <Typography variant="body2" color={theme.colors.paragraphGray}>
                <FormattedMessage
                  id="faq.questions.q8.content.listItem1"
                  values={{ b: (chunks) => <strong>{chunks}</strong> }}
                />
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2" color={theme.colors.paragraphGray}>
                <FormattedMessage
                  id="faq.questions.q8.content.listItem2"
                  values={{ b: (chunks) => <strong>{chunks}</strong> }}
                />
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2" color={theme.colors.paragraphGray}>
                <FormattedMessage
                  id="faq.questions.q8.content.listItem3"
                  values={{ b: (chunks) => <strong>{chunks}</strong> }}
                />
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2" color={theme.colors.paragraphGray}>
                <FormattedMessage
                  id="faq.questions.q8.content.listItem4"
                  values={{ b: (chunks) => <strong>{chunks}</strong> }}
                />
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2" color={theme.colors.paragraphGray}>
                <FormattedMessage
                  id="faq.questions.q8.content.listItem5"
                  values={{ b: (chunks) => <strong>{chunks}</strong> }}
                />
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2" color={theme.colors.paragraphGray}>
                <FormattedMessage
                  id="faq.questions.q8.content.listItem6"
                  values={{ b: (chunks) => <strong>{chunks}</strong> }}
                />
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2" color={theme.colors.paragraphGray}>
                <FormattedMessage
                  id="faq.questions.q8.content.listItem7"
                  values={{ b: (chunks) => <strong>{chunks}</strong> }}
                />
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2" color={theme.colors.paragraphGray}>
                <FormattedMessage
                  id="faq.questions.q8.content.listItem8"
                  values={{ b: (chunks) => <strong>{chunks}</strong> }}
                />
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2" color={theme.colors.paragraphGray}>
                <FormattedMessage
                  id="faq.questions.q8.content.listItem9"
                  values={{ b: (chunks) => <strong>{chunks}</strong> }}
                />
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2" color={theme.colors.paragraphGray}>
                <FormattedMessage
                  id="faq.questions.q8.content.listItem10"
                  values={{ b: (chunks) => <strong>{chunks}</strong> }}
                />
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2" color={theme.colors.paragraphGray}>
                <FormattedMessage
                  id="faq.questions.q8.content.listItem11"
                  values={{ b: (chunks) => <strong>{chunks}</strong> }}
                />
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2" color={theme.colors.paragraphGray}>
                <FormattedMessage
                  id="faq.questions.q8.content.listItem12"
                  values={{ b: (chunks) => <strong>{chunks}</strong> }}
                />
              </Typography>
            </ListItem>
          </List>
        </FAQSection>
      ),
    },
    {
      id: 'faq-9',
      title: `9. ${intl.formatMessage({ id: 'faq.questions.q9.title' })}`,
      contentItems: (
        <FAQSection>
          <Typography variant="body2" color={theme.colors.paragraphGray}>
            <FormattedMessage id="faq.questions.q9.content.intro" />
          </Typography>
          <List>
            <ListItem>
              <Typography variant="body2" color={theme.colors.paragraphGray}>
                <FormattedMessage id="faq.questions.q9.content.listItem1" />
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2" color={theme.colors.paragraphGray}>
                <FormattedMessage id="faq.questions.q9.content.listItem2" />
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2" color={theme.colors.paragraphGray}>
                <FormattedMessage id="faq.questions.q9.content.listItem3" />
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2" color={theme.colors.paragraphGray}>
                <FormattedMessage id="faq.questions.q9.content.listItem4" />
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2" color={theme.colors.paragraphGray}>
                <FormattedMessage id="faq.questions.q9.content.listItem5" />
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2" color={theme.colors.paragraphGray}>
                <FormattedMessage id="faq.questions.q9.content.listItem6" />
              </Typography>
            </ListItem>
          </List>
        </FAQSection>
      ),
    },
    {
      id: 'faq-10',
      title: `10. ${intl.formatMessage({ id: 'faq.questions.q10.title' })}`,
      contentItems: (
        <FAQSection>
          <Typography variant="body2" color={theme.colors.paragraphGray}>
            <FormattedMessage id="faq.questions.q10.content.intro" />
          </Typography>
          <List>
            <ListItem>
              <Typography variant="body2" color={theme.colors.paragraphGray}>
                <FormattedMessage id="faq.questions.q10.content.listItem1" />
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2" color={theme.colors.paragraphGray}>
                <FormattedMessage id="faq.questions.q10.content.listItem2" />
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2" color={theme.colors.paragraphGray}>
                <FormattedMessage id="faq.questions.q10.content.listItem3" />
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2" color={theme.colors.paragraphGray}>
                <FormattedMessage id="faq.questions.q10.content.listItem4" />
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2" color={theme.colors.paragraphGray}>
                <FormattedMessage id="faq.questions.q10.content.listItem5" />
              </Typography>
            </ListItem>
          </List>
          <Typography variant="body2" color={theme.colors.paragraphGray}>
            <FormattedMessage
              id="faq.questions.q10.content.footer"
              values={{ b: (chunks) => <strong>{chunks}</strong> }}
            />
          </Typography>
        </FAQSection>
      ),
    },
    {
      id: 'faq-11',
      title: `11. ${intl.formatMessage({ id: 'faq.questions.q11.title' })}`,
      contentItems: (
        <FAQSection>
          <Typography variant="body2" color={theme.colors.paragraphGray}>
            <FormattedMessage id="faq.questions.q11.content.intro" />
          </Typography>
          <List>
            <ListItem>
              <Typography variant="body2" color={theme.colors.paragraphGray}>
                <FormattedMessage id="faq.questions.q11.content.listItem1" />
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2" color={theme.colors.paragraphGray}>
                <FormattedMessage id="faq.questions.q11.content.listItem2" />
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2" color={theme.colors.paragraphGray}>
                <FormattedMessage id="faq.questions.q11.content.listItem3" />
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2" color={theme.colors.paragraphGray}>
                <FormattedMessage id="faq.questions.q11.content.listItem4" />
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2" color={theme.colors.paragraphGray}>
                <FormattedMessage id="faq.questions.q11.content.listItem5" />
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2" color={theme.colors.paragraphGray}>
                <FormattedMessage id="faq.questions.q11.content.listItem6" />
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2" color={theme.colors.paragraphGray}>
                <FormattedMessage id="faq.questions.q11.content.listItem7" />
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2" color={theme.colors.paragraphGray}>
                <FormattedMessage id="faq.questions.q11.content.listItem8" />
              </Typography>
            </ListItem>
          </List>
          <Typography variant="body2" color={theme.colors.paragraphGray}>
            <FormattedMessage id="faq.questions.q11.content.footer" />
          </Typography>
        </FAQSection>
      ),
    },
  ];
};
