import { ExerciseSelection } from 'state/alternative-exercises';

export enum AlternativeExercisesActionTypes {
  SET_ALTERNATIVE_EXERCISE = 'SET_ALTERNATIVE_EXERCISE',
  REMOVE_ALTERNATIVE_EXERCISE = 'REMOVE_ALTERNATIVE_EXERCISE',
  RESET_ALTERNATIVE_EXERCISES = 'RESET_ALTERNATIVE_EXERCISES',
}

export const setAlternativeExercise = (selection: ExerciseSelection) => ({
  type: AlternativeExercisesActionTypes.SET_ALTERNATIVE_EXERCISE as const,
  payload: selection,
});

export const removeAlternativeExercise = (sectionExerciseId: string) => ({
  type: AlternativeExercisesActionTypes.REMOVE_ALTERNATIVE_EXERCISE as const,
  payload: { sectionExerciseId },
});

export const resetAlternativeExercises = () => ({
  type: AlternativeExercisesActionTypes.RESET_ALTERNATIVE_EXERCISES as const,
});

export type SetAlternativeExerciseAction = ReturnType<typeof setAlternativeExercise>;
export type RemoveAlternativeExerciseAction = ReturnType<typeof removeAlternativeExercise>;
export type ResetAlternativeExercisesAction = ReturnType<typeof resetAlternativeExercises>;

export type AlternativeExercisesActions =
  | SetAlternativeExerciseAction
  | RemoveAlternativeExerciseAction
  | ResetAlternativeExercisesAction;

export default { setAlternativeExercise, removeAlternativeExercise, resetAlternativeExercises };
