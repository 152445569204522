import React from 'react';
import { ContentData } from 'app/ui/components/molecules/content-item-areas-wrapper';
import AudienceLessonSectionBreakdown from 'app/ui/components/molecules/whiteboard-slide/section-breakdown/audience';
import PresenterLessonSectionBreakdown from 'app/ui/components/molecules/whiteboard-slide/section-breakdown/presenter';

type LessonSectionBreakdownProps = {
  sectionData: ContentData,
  isSmallVariant?: boolean,
  isOverviewMode?: boolean,
  isPresenterView?: boolean,
};

const LessonSectionBreakdown: React.FC<LessonSectionBreakdownProps> = ({
  sectionData,
  isSmallVariant,
  isOverviewMode,
  isPresenterView,
}) => {
  return isPresenterView ? (
    <PresenterLessonSectionBreakdown
      sectionData={sectionData}
      isOverviewMode={isOverviewMode}
    />
  ) : (
    <AudienceLessonSectionBreakdown
      sectionData={sectionData}
      isSmallVariant={isSmallVariant}
      isOverviewMode={isOverviewMode}
    />
  );
};

export default LessonSectionBreakdown;
