import React from 'react';
import styled, { useTheme } from 'styled-components';
import { useIntl } from 'react-intl';

import Typography from 'app/ui/components/atoms/typography';
import Button from 'app/ui/components/atoms/button';
import VideoPlayer from 'app/ui/components/molecules/video-component';

import ChevronRight from 'app/ui/components/atoms/icons/chevron-right';
import { ComponentAlignment } from 'app/types/graphql';

type Video = {
  signedUrl?: string;
  thumbnail?: {
    signedUrl?: string;
  } | null;
};

type ResourceSectionDetailsProps = {
  title: string;
  mediaFirst: boolean;
  content?: string | null;
  imageUrl?: string;
  video?: Video | null;
  documentUrl?: string;
  linkUrl?: string;
  componentAlignment: ComponentAlignment,
};

const Wrapper = styled.div<{ mediaFirst: boolean, isVertical: boolean }>`
  display: flex;

  margin: 0;

  ${({ isVertical }) => `flex-direction: ${isVertical ? 'column-reverse' : 'column'}`};

  ${({ theme: { media, spacing }, mediaFirst, isVertical }) =>
    `${media.medium(
      () => `
        margin: 0;
        gap: ${isVertical ? spacing(8) : '5rem'};

        ${isVertical
          ? 'width: 48.75rem; margin: 0 auto;'
          : `flex-direction: ${mediaFirst ? 'row' : 'row-reverse'}`
        };
    `,
    )}`};
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const TitleWrapper = styled.div<{ isVertical?: boolean }>`
  margin: 0 0 1.5rem 0;
  ${({ theme: { media } }) => `${media.medium(() => 'margin: 0 0 0.6rem')}`};

  ${({ isVertical }) => isVertical && 'text-align: center;'}
`;

const TextContentWrapper = styled.div`
  margin: 0 0 1.5rem 0;
  ${({ theme: { media } }) => `${media.medium(() => 'margin: 0.6rem 0')}`};

  ul {
    padding-left: ${({ theme: { spacing } }) => spacing(4)};
    margin-left: 0;
  }
`;

const MediaWrapper = styled.div`
  display: flex;
  flex: 1;
`;

const ButtonWrapper = styled.div`
  margin: 0 0 1.25rem;
  ${({ theme: { media } }) => `${media.medium(() => 'margin: 0.6rem 0;')}`};
`;

const MobileButtonWrapper = styled(ButtonWrapper)`
  margin: 1.25rem 0 1.25rem;
  ${({ theme: { media } }) => {
    return `
        ${media.medium(
          () => `
            display: none;
            `,
        )}
        `;
  }}
`;

const DesktopButtonWrapper = styled(ButtonWrapper)`
  display: none;
  ${({ theme: { media } }) => {
    return `
        ${media.medium(
          () => `
            display: block;
            `,
        )}
        `;
  }}
`;

const StyledImage = styled.img<{ isVertical: boolean }>`
  width: 100%;
  margin: ${({ isVertical, theme: { spacing } }) => (isVertical ? `0 auto ${spacing(6)}` : '0')};

  ${({ theme: { media }, isVertical }) =>
    media.medium(() => `
      ${isVertical ? 'width: auto; max-width: 100%;' : 'width: 100%;'}
    `)};
`;

const ChevronIcon = styled(ChevronRight)`
  padding-top: 0.25rem;
  width: 1rem;
  height: 1rem;
`;

const ResourceSectionDetails: React.FC<ResourceSectionDetailsProps> = ({
  title,
  content,
  imageUrl,
  video,
  documentUrl,
  linkUrl,
  mediaFirst,
  componentAlignment,
}) => {
  const theme = useTheme();
  const intl = useIntl();

  const documentLabel = intl.formatMessage({ id: 'resource.buttons.document' });
  const folderLabel = intl.formatMessage({ id: 'resource.buttons.folder' });

  const isVertical = componentAlignment === ComponentAlignment.VERTICAL;

  return (
    <Wrapper mediaFirst={mediaFirst} isVertical={isVertical}>
      <TextWrapper>
        <TitleWrapper isVertical={isVertical}>
          <Typography variant="h3">{title}</Typography>
        </TitleWrapper>
        <TextContentWrapper>
          <Typography variant="body1" htmlContent={content || ''}></Typography>
        </TextContentWrapper>
        {documentUrl && (
          <DesktopButtonWrapper>
            <Button
              label={documentLabel}
              type="primary"
              onClick={() => (window.location.href = documentUrl)}
              icon={<ChevronIcon color={theme.colors.black} />}
              fullWidthMob
            />
          </DesktopButtonWrapper>
        )}
        {linkUrl && (
          <DesktopButtonWrapper>
            <Button
              label={folderLabel}
              type="primary"
              onClick={() => (window.location.href = linkUrl)}
              icon={<ChevronIcon color={theme.colors.black} />}
              fullWidthMob
            />
          </DesktopButtonWrapper>
        )}
      </TextWrapper>
      {(video?.signedUrl || imageUrl) && (
        <MediaWrapper>
          {video?.signedUrl ? (
            <VideoPlayer
              src={video.signedUrl}
              title=""
              duration=""
              showRewindAndForward
              thumbnail={video.thumbnail?.signedUrl}
            />
          ) : (
            <StyledImage isVertical={isVertical} src={imageUrl} />
          )}
        </MediaWrapper>
      )}
      {documentUrl && (
        <MobileButtonWrapper>
          <Button
            label={documentLabel}
            type="primary"
            onClick={() => (window.location.href = documentUrl)}
            icon={<ChevronIcon color={theme.colors.black} />}
            fullWidthMob
          />
        </MobileButtonWrapper>
      )}
      {linkUrl && (
        <MobileButtonWrapper>
          <Button
            label={folderLabel}
            type="primary"
            onClick={() => (window.location.href = linkUrl)}
            icon={<ChevronIcon color={theme.colors.black} />}
            fullWidthMob
          />
        </MobileButtonWrapper>
      )}
    </Wrapper>
  );
};

export default ResourceSectionDetails;
