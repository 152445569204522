import React from 'react';
import styled from 'styled-components';
import useTimer, { TimerMessageType } from 'app/hooks/use-timer';

import PlayIcon from 'app/assets/play-icon.svg';
import PauseIcon from 'app/assets/pause-icon.svg';
import RestartIcon from 'app/assets/restart-icon.svg';

type TimerControlsProps = {
  timerId: string,
  initialDuration: number,
  className?: string,
};

const ControlsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
  height: 100%;
`;

const ControlButton = styled.button<{ $isPrimary: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, $isPrimary }) => $isPrimary ? theme.colors.white : theme.colors.blackSwan};
  border: none;
  border-radius: ${({ theme }) => theme.spacing(3)};
  width: 100%;
  height: 50%;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const ControlButtonIcon = styled.img`
  width: ${({ theme }) => theme.spacing(8)};
  height: ${({ theme }) => theme.spacing(8)};
`;

const TimerControls: React.FC<TimerControlsProps> = ({
  timerId,
  initialDuration,
  className,
}) => {
  const { isRunning, play, pause, restart } = useTimer({
    timerId,
    initialDuration,
  });

  const handleTogglePlayPause = () => (
    isRunning ? pause() : play()
  );

  return (
    <ControlsWrapper className={className}>
      <ControlButton onClick={handleTogglePlayPause} $isPrimary={true}>
        <ControlButtonIcon src={isRunning ? PauseIcon : PlayIcon} alt={isRunning ? 'Pause' : 'Play'} />
      </ControlButton>
      <ControlButton onClick={restart} $isPrimary={false}>
        <ControlButtonIcon src={RestartIcon} alt="Restart" />
      </ControlButton>
    </ControlsWrapper>
  );
};

export default TimerControls;
