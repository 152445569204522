import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  ${({ theme: { spacing } }) => `
    width: ${spacing(6)};
    height: ${spacing(6)};
  `}
`;

type Props = {
  color: string,
  className?: string,
};

const Spin = ({ color, className = '' }: Props) => (
  <Svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.91408 14.8036C8.91408 15.5607 8.29498 16.1744 7.53127 16.1744C6.76756 16.1744 6.14845 15.5607 6.14845 14.8036C6.14845 14.0466 6.76756 13.4329 7.53127 13.4329C8.29498 13.4329 8.91408 14.0466 8.91408 14.8036Z" fill={color}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M21.7975 3.17C22.0675 2.90234 22.0675 2.46839 21.7975 2.20074C21.5275 1.93309 21.0897 1.93309 20.8197 2.20074L18.7517 4.2507C18.7257 4.27641 18.6906 4.29085 18.6539 4.29085H15.5887C15.2068 4.29085 14.8973 4.5977 14.8973 4.97622C14.8973 5.35473 15.2068 5.66158 15.5887 5.66158H16.4823L12.962 11.0989C12.6194 11.2155 12.3006 11.3828 12.0147 11.5919C11.057 10.2805 9.5281 9.40586 7.78981 9.32657L6.96239 6.53823L7.95049 6.38152C9.29417 6.16841 9.3675 4.27855 8.0443 3.96375L4.50583 3.12194C3.72324 2.93576 2.97086 3.52375 2.97086 4.32154V5.72456C2.97086 6.48365 3.65586 7.06266 4.41202 6.94273L5.58578 6.75657L6.38184 9.43923C3.87865 9.96366 2 12.1662 2 14.8036C2 17.0309 3.33973 18.948 5.26393 19.8063V20.6293H3.88112V22H6.64675V20.2169C6.93476 20.2627 7.23021 20.2866 7.53127 20.2866C9.61289 20.2866 11.4259 19.1467 12.3699 17.4624C12.8836 17.7511 13.4775 17.9159 14.1103 17.9159C14.146 17.9159 14.1816 17.9154 14.2171 17.9144L16.904 20.5779V21.8757H19.6697V20.5049H18.786L15.7642 17.5094C16.8825 16.9213 17.6443 15.7553 17.6443 14.4129C17.6443 12.6761 16.3692 11.2345 14.6964 10.9577L18.1254 5.66158H18.6539C19.0573 5.66158 19.4442 5.50273 19.7295 5.21996L21.7975 3.17ZM7.53127 17.5451C9.05868 17.5451 10.2969 16.3177 10.2969 14.8036C10.2969 13.2896 9.05868 12.0622 7.53127 12.0622C6.00385 12.0622 4.76563 13.2896 4.76563 14.8036C4.76563 16.3177 6.00385 17.5451 7.53127 17.5451ZM14.8786 14.4129C14.8786 14.8335 14.5346 15.1745 14.1103 15.1745C13.686 15.1745 13.342 14.8335 13.342 14.4129C13.342 13.9922 13.686 13.6513 14.1103 13.6513C14.5346 13.6513 14.8786 13.9922 14.8786 14.4129Z" fill={color}/>
  </Svg>
);

export default Spin;
