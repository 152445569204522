import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  ${({ theme: { spacing } }) => `
    width: ${spacing(6)};
    height: ${spacing(6)};
  `}
`;

type Props = {
  className?: string,
};

const ChipperDark = ({ className = '' }: Props) => (
  <Svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M2 0C0.895431 0 0 0.89543 0 2V22C0 23.1046 0.89543 24 2 24H22C23.1046 24 24 23.1046 24 22V2C24 0.895431 23.1046 0 22 0H2ZM15.5 5.58333C13.8892 5.58333 12.5833 6.88917 12.5833 8.5C12.5833 10.1108 13.8892 11.4167 15.5 11.4167C17.1108 11.4167 18.4167 10.1108 18.4167 8.5C18.4167 6.88917 17.1108 5.58333 15.5 5.58333ZM5.58333 12.5833V18.4167H11.4167V12.5833H5.58333ZM15.5 12.5833L12.5833 18.4167H15.5H18.4167L15.5 12.5833ZM6.9532 5.92201L5.92201 6.9532L7.4688 8.5L5.92201 10.0468L6.9532 11.078L8.5 9.5312L10.0468 11.078L11.078 10.0468L9.5312 8.5L11.078 6.9532L10.0468 5.92201L8.5 7.4688L6.9532 5.92201Z" fill="black"/>
  </Svg>
);

export default ChipperDark;
