import React, { Fragment } from 'react';
import styled from 'styled-components';

import { useQuery } from '@apollo/client';
import { useParams, useLocation } from 'react-router-dom';

import GenericPage from 'app/ui/components/layouts/generic-page';

import Error from 'app/ui/components/molecules/error';
import Loading from 'app/ui/components/molecules/loading';
import ResourceHeader from 'app/ui/components/molecules/resource-header';
import ResourceSectionDetails from 'app/ui/components/molecules/resource-section-details';
import Breadcrumb from 'app/ui/components/molecules/breadcrumb';
import ResourceNavigation from 'app/ui/components/molecules/resource-navigation';
import MoreResourceContent from 'app/ui/components/molecules/more-resource-content';

import { ResourcePage as RESOURCE } from 'app/pages/resource/resource.gql';
import { ComponentAlignment, ResourcePageQuery, ResourcePageQueryVariables } from 'app/types/graphql';

const BreadcrumbWrapper = styled.div`
  display: none;

  ${({ theme: { media, spacing } }) =>
    `${media.medium(
      () => `
        display: flex;
        margin: ${spacing(14)} 0 ${spacing(6)} 0;
    `,
    )}`};
`;

const ResourceSectionDetailsWrapper = styled.div`
  & > div:first-child {
    ${({ theme: { spacing } }) => `margin-top: ${spacing(14)};`};
  }

  ${({ theme: { media } }) =>
    `${media.medium(
      () => `
        & > div:first-child {
          margin-top: 0;
        }
    `,
    )}`};

  & > div > div {
    margin-top: 0;
  }

  & > div:not(:first-child) {
    ${({ theme: { spacing } }) => `margin-top: ${spacing(24)};`}

    &[data-alignment="horizontal"][data-prev-alignment="horizontal"] {
      ${({ theme: { spacing } }) => `margin-top: ${spacing(32)};`}
    }
  }
`;

const Divider = styled.hr`
  border: 0;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.blackSwan};
  width: 100%;
  margin: ${({ theme }) => `${theme.spacing(16)} auto`};

  ${({ theme: { media } }) => media.medium(() => 'width: 48.75rem;')};
`;

type ResourcePageProps = {
  resourceId: string;
  resourceListId?: string;
};

const { VERTICAL } = ComponentAlignment;

const ResourcePage: React.FC<ResourcePageProps> = ({ resourceId, resourceListId }: ResourcePageProps) => {
  const { data, loading, error } = useQuery<ResourcePageQuery, ResourcePageQueryVariables>(RESOURCE, {
    variables: { id: resourceId },
  });

  if (loading) {
    return <Loading />;
  }

  if (error || !data || !data.resourceById) {
    return <Error />;
  }

  const { title, content, image, sections = [] } = data.resourceById;

  const shouldShowDivider = (index: number) => {
    if (!sections || index === sections?.length - 1) {
      return false;
    }

    const isCurrentVertical = sections[index].componentAlignment === VERTICAL;
    const isNextVertical = sections[index + 1].componentAlignment === VERTICAL;
    
    return isCurrentVertical && isNextVertical;
  };

  return (
    <GenericPage>
      <ResourceHeader title={title} content={content} imageUrl={image?.url || image?.signedUrl} />
      <BreadcrumbWrapper>
        <Breadcrumb contentTitle={title} />
      </BreadcrumbWrapper>
      <ResourceSectionDetailsWrapper>
        {sections?.map(({ title, content, images, videos, documents, links, componentAlignment }, i) => {
          const imageUrl = images?.find(({ signedUrl }) => !!signedUrl)?.signedUrl;
          const video = videos?.find(({ signedUrl }) => !!signedUrl);
          const documentUrl = documents?.find(({ signedUrl }) => !!signedUrl)?.signedUrl;
          const linkUrl = links?.length ? links[0].url : null;

          const prevAlignment = i > 0 ? sections[i - 1].componentAlignment.toLowerCase() : null;

          return (
            <Fragment key={`section-fragment-${i}`}>
              <div 
                data-alignment={componentAlignment.toLowerCase()} 
                data-prev-alignment={prevAlignment}
              >
                <ResourceSectionDetails
                  key={`section-${i}`}
                  title={title}
                  content={content}
                  imageUrl={imageUrl}
                  video={video}
                  documentUrl={documentUrl}
                  linkUrl={linkUrl}
                  mediaFirst={!!(i % 2)}
                  componentAlignment={componentAlignment}
                />
              </div>
              {shouldShowDivider(i) && <Divider key={`section-divider-${i}`} />}
            </Fragment>
          );
        })}
      </ResourceSectionDetailsWrapper>

      {resourceListId && (
        <ResourceNavigation
          resourceListId={resourceListId}
          currentResourceId={resourceId}
        />
      )}

      <MoreResourceContent limit={3} />
    </GenericPage>
  );
};

const Resource: React.FC = () => {
  const { id: resourceId } = useParams();
  const location = useLocation();

  const resourceListId = React.useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('resourceListId') || undefined;
  }, [location.search]);

  if (!resourceId) {
    return <Error />;
  }

  return (
    <ResourcePage
      resourceId={resourceId}
      resourceListId={resourceListId}
    />
  );
};

export default Resource;
