import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  ${({ theme: { spacing } }) => `
    width: ${spacing(6)};
    height: ${spacing(6)};
  `}
`;

type Props = {
  className?: string,
};

const TO2MDark = ({ className = '' }: Props) => (
  <Svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M2 0C0.895431 0 0 0.89543 0 2V22C0 23.1046 0.89543 24 2 24H22C23.1046 24 24 23.1046 24 22V2C24 0.895431 23.1046 0 22 0H2ZM11.6134 7.40085C11.6134 10.1105 13.536 12.0001 16.2187 12.0001C18.8758 12.0001 20.7856 10.1171 20.7856 7.40741C20.7856 4.71087 18.8758 2.82788 16.2187 2.82788C13.536 2.82788 11.6134 4.70431 11.6134 7.40085ZM16.2314 9.67094C15.0178 9.67094 14.2003 8.75241 14.2003 7.40741C14.2003 6.08211 15.0178 5.1767 16.2314 5.1767C17.4131 5.1767 18.2051 6.08211 18.2051 7.40741C18.2051 8.75241 17.4131 9.67094 16.2314 9.67094ZM5.47266 15.8501L3.75586 15.0825C4.05469 14.0981 5.08594 12.9146 6.84375 12.9146C8.66016 12.9146 9.90234 13.9751 9.90234 15.563C9.90234 16.9282 8.63086 17.8599 7.5 18.6626C7.32422 18.7856 6.97852 19.0669 6.96094 19.0903H9.94922V20.9946H4.03125V20.1626C4.03125 18.9255 4.67802 18.434 5.9897 17.4371L5.98985 17.437L5.99008 17.4368L5.99042 17.4366C6.10745 17.3476 6.22979 17.2546 6.35742 17.1567C7.17188 16.5298 7.5293 16.1724 7.5293 15.6216C7.5293 15.1587 7.21289 14.8481 6.69727 14.8481C6.14648 14.8481 5.69531 15.311 5.47266 15.8501ZM11.2148 20.9946V13.0376H13.6582L15.7266 17.6138L17.8008 13.0376H20.2441V20.9946H17.9355V17.0337L16.4238 20.5435H15.0352L13.5234 17.0337V20.9946H11.2148ZM4.05586 5.31636C3.89017 5.31636 3.75586 5.18205 3.75586 5.01636V3.66966C3.75586 3.50397 3.89017 3.36966 4.05586 3.36966H10.5201C10.6858 3.36966 10.8201 3.50397 10.8201 3.66966V5.01636C10.8201 5.18205 10.6858 5.31636 10.5201 5.31636H8.74944C8.58376 5.31636 8.44944 5.45068 8.44944 5.61636V11.1789C8.44944 11.3446 8.31513 11.4789 8.14944 11.4789H6.36682C6.20114 11.4789 6.06682 11.3446 6.06682 11.1789V5.61636C6.06682 5.45068 5.93251 5.31636 5.76682 5.31636H4.05586Z" fill="black"/>
  </Svg>
);

export default TO2MDark;
