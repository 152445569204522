import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  ${({ theme: { spacing } }) => `
    width: ${spacing(4)};
    height: ${spacing(4)};
  `}
`;

type Props = {
  color: string,
  className?: string,
};

const ChevronRight = ({ className = '', color }: Props) => (
  <Svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M19 7L12 15L5 7L4 8C5.69 10.13 10.411 15.2789 12 17C13.162 15.7222 12.02 16.9689 20 8L19 7Z" fill={color} stroke={color}/>
  </Svg>
);

export default ChevronRight;
